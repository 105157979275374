import {useState} from 'react'

export const useUso = () => {
  const [uso, setUso] = useState(null)

  async function fetchZonaUso() {
    let zonaUso = [
      'Apartamento em condomínio',
      'Armazéns gerais e depósitos',
      'Asilo, orfanato, creche, seminário ou convento',
      'Cinema, teatro, casa de diversão, clube ou congênere',
      'Clube esportivo',
      'Cortiço',
      'Escola',
      'Escritório/consultório em condomínio (unidade autônoma)',
      'Estação radioemissora, de televisão ou empresa jornalística',
      'Flat de uso comercial (semelhante a hotel)',
      'Garagem (exclusive em prédio em condomínio)',
      'Garagem (unidade autônoma) de prédio de garagens',
      'Garagem (unidade autônoma) em edifício em condomínio de uso exclusivamente residencial',
      'Hospital, ambulatório, casa de saúde e assemelhados',
      'Hotel, pensão ou hospedaria',
      'Indústria',
      'Loja',
      'Loja em edifício em condomínio (unidade autônoma)',
      'Loja e residência (predominância comercial)',
      'Não residencial',
      'Oficina',
      'Outras edificações de uso coletivo, com utilização múltipla',
      'Outras edificações de uso comercial, com utilização múltipla',
      'Outras edificações de uso de serviço, com utilização múltipla',
      'Outras edificações de uso especial, com utilização múltipla',
      'Posto de serviço',
      'Prédio de apartamento, não em condomínio, de uso exclusivamente residencial',
      'Prédio de apartamento, não em condomínio, de uso misto (apartamentos e escritórios e/ou consultórios), com ou sem loja (predominância residencial)',
      'Prédio de escritório, não em condomínio, de uso misto (apartamentos e escritórios e/ou consultórios) com ou sem loja (predominância comercial)',
      'Prédio de escritório ou consultório, não em condomínio, com ou sem lojas',
      'Residência',
      'Residência coletiva, exclusive cortiço (mais de uma residência no lote)',
      'Residência e outro uso (predominância residencial)',
      'Templo',
      'Terreno',
    ]
    setUso(zonaUso)
  }

  return {
    fetchZonaUso,
    uso,
    setUso,
  }
}
