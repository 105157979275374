export const Tooltip = ({hoverInfo}) => {
  return (
    hoverInfo && (
      <div className='tooltip-prospecacao'>
        <p>
          <span>Logradouro: </span>
          {hoverInfo.logradouro}
        </p>
        <p>
          <span>Numero contribuinte: </span>
          {hoverInfo.numero_contribuinte}
        </p>
        <p>
          <span>Área: </span>
          {hoverInfo.area}m²
        </p>
        <p>
          <span>Setor/Quadra: </span>
          {hoverInfo.setor_quadra}
        </p>
        <p>
          <span>Tipo: </span>
          {hoverInfo.numero_contribuinte
            ? hoverInfo.numero_contribuinte.substring(6, 10) === '0000'
              ? 'Multi-Contribuinte'
              : 'Residência'
            : '-'}
        </p>
      </div>
    )
  )
}
