import {useState} from 'react'
import Select from 'react-select'
import {QueryGetEquipe} from '../../../../graphql/services'
import {FormLabel} from 'react-bootstrap'

type Equipe = {
  value: number
  label: string
}
export function GetEquipeChosen({SetValueEquipe, valueEquipe}) {
  let equipeesArray = []

  const [equipe, setEquipe] = useState<Equipe[]>(null)

  // useEffect(() => {
  //   setEquipe(valueEquipe)
  // }, [valueEquipe])

  const {data} = QueryGetEquipe({
    variables: {
      pagination: {
        pagina: 0,
        quantidade: 100,
      },
    },
  })

  if (!data) {
    return (
      <>
        <div className='form-group'>
          <FormLabel className='form-label'>Equipe</FormLabel>
          {/* <label className='col-form-label'>Equipe Disponiveis:</label> */}
          <Select
            options={equipeesArray}
            onChange={(e) => {
              setEquipe(e.value)
            }}
            value={equipeesArray.filter((e) => e.value)[0]}
            name='id_equipe'
            placeholder='Equipe Disponíveis'
          />
        </div>
      </>
    )
  }
  // equipeesArray.push({value: '', label: '--selecione--'})
  data.GetEquipe.result.map((Options) => {
    equipeesArray.push({value: Options.id, label: Options.nome})
    return []
  })

  return (
    <>
      <div className='form-group'>
        <FormLabel className='form-label'>Equipe</FormLabel>
        {/* <label className='col-form-label'>Equipe Disponiveis:</label> */}
        <Select
          value={
            equipe
              ? equipe
              : valueEquipe
              ? equipeesArray.filter((e) => valueEquipe.indexOf(e.value) !== -1)
              : ''
          }
          options={equipeesArray}
          isMulti
          onChange={(e) => {
            setEquipe([...e])
            SetValueEquipe(e.map((e) => e.value))
          }}
          name='id_equipe'
          placeholder='Equipe'
        />
      </div>
    </>
  )
}
