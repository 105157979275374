import React, {useCallback, useEffect, useState} from 'react'
import {Autocomplete, GoogleMap} from '@react-google-maps/api'
import {Imoveis} from '../../../../../context/types'
import {latLng} from '../../../../../../../components/util'
import useMap from './useMap'
import useAutocomplete from '../../../../../hooks/useAutocomplete'
import {useDataTese} from '../../../../../context/DataContext'
import {MVTLayer} from '@deck.gl/geo-layers'
import {Tooltip} from './Tooltip'
import {PainelSelecionados} from './PainelSelecionados'

type Props = {
  height: number
  imoveis: Imoveis[]
}

const MapView: React.FC<Props> = ({height, imoveis}) => {
  const {
    lotes,
    filter,
    fetchData,
    setLotesSelecionados,
    lotesSelecionados,
    findExistLotesSelecionados,
    removeItemLotesSelecionados,
  } = useDataTese()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [center, setCenter] = useState<latLng>()
  // const [selected, setSelected] = useState([])
  const [lotesFiltrados, setLotesFiltrados] = useState([])
  const {onLoad, onUnmount, map, deckOverlay} = useMap()
  const {onPlaceChanged, buscaPosition, onLoadAutoComplete} = useAutocomplete(map)
  const [hoverInfo, setHoverInfo] = useState<{
    x: number
    y: number
    numero_contribuinte: string
    setor_quadra: string
    logradouro: string
    gid: number
    area: number
  } | null>(null)

  useEffect(() => {
    if (filter.tese) {
      fetchData({
        type: 'Point',
        geometry: {
          type: 'Point',
          coordinates: [-46.6, -23.6],
        },
        properties: {
          teste: 'teste',
        },
      })
    }
  }, [fetchData, filter])

  useEffect(() => {
    if (map && lotes) {
      let selectedTmp = []
      // eslint-disable-next-line array-callback-return
      lotes.features.map((feature) => {
        selectedTmp.push(
          `${feature.properties.setor}${feature.properties.quadra}${feature.properties.lote}`
        )
      })
      setLotesFiltrados([...selectedTmp])

      // Adiciona o GeoJSON na Data Layer do mapa
      map.data.addGeoJson(lotes as any)

      //Ajusta o estilo das features (opcional)
      map.data.setStyle({
        fillColor: 'green',
        strokeColor: 'grey',
        strokeWeight: 1,
      })

      // Centraliza o mapa com base nas features adicionadas
      const bounds = new google.maps.LatLngBounds()
      map.data.forEach((feature) => {
        feature.getGeometry()?.forEachLatLng((latlng) => {
          bounds.extend(latlng)
        })
      })

      if (!bounds.isEmpty()) {
        map.fitBounds(bounds)
      }
      map.data.setMap(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lotes])

  const onClick = useCallback((info: any) => {
    if (info.object) {
      let checkExists = findExistLotesSelecionados(
        info.object.properties.numero_contribuinte.substring(0, 10)
      )
      console.log('checkExists')
      console.log(checkExists)
      if (checkExists.length > 0) {
        removeItemLotesSelecionados(info.object.properties.numero_contribuinte.substring(0, 10))
      } else {
        let cod = info.object.properties
        setLotesSelecionados([cod])
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onHover = useCallback((info: any) => {
    if (info.object) {
      setHoverInfo({
        x: info.x,
        y: info.y,
        numero_contribuinte: info.object.properties.numero_contribuinte,
        area: Number(info.object.properties.qt_area_te),
        setor_quadra: info.object.properties.setor_quadra,
        logradouro: info.object.properties.nm_logrado,
        gid: info.object.properties.gid,
      })
    } else {
      setHoverInfo(null)
    }
  }, [])

  function getFillColor(data) {
    if (findExistLotesSelecionados(data.properties.numero_contribuinte.substr(0, 10)).length > 0) {
      return [35, 167, 117, 255]
    } else {
      if (lotesFiltrados.includes(data.properties.numero_contribuinte.substr(0, 10))) {
        return [0, 130, 205, 255]
      } else {
        return [0, 0, 255, 50]
      }
    }
  }

  useEffect(() => {
    if (deckOverlay) {
      deckOverlay.setProps({
        layers: [
          new MVTLayer({
            id: 'mvt-layer',
            data: 'https://tile.urbit.com.br/tile/lotesfinal/{z}/{x}/{y}.pbf?s=sao_paulo',
            minZoom: 10,
            maxZoom: 20,
            autoHighlight: true,
            highlightColor: [0, 255, 0],
            getFillColor: getFillColor,
            getLineColor: [255, 255, 255, 100], // Vermelho
            lineWidthMinPixels: 2,
            pickable: true, // Permite interação com a camada
            onClick: onClick,
            onHover: onHover,

            updateTriggers: {
              // getPosition: props.getPosition,
              getFillColor: getFillColor,
            },
          }),
        ],
      })
    }
  }, [deckOverlay, lotesFiltrados, onHover, lotesSelecionados])

  console.log('lotesSelecionados Geral')
  console.log(lotesSelecionados)
  return (
    <>
      {/* <button
          onClick={() => {
            onchangeAdress(map, {
              latitude: -23.5986377,
              longitude: -46.6689923,
              endereco_completo: 'R. Diogo Jácome, 818 - Moema, São Paulo - SP, 04512-001, Brasil',
              logradouro: 'Rua Diogo Jácome',
              numero: '818',
              bairro: 'Moema',
              cidade: 'São Paulo',
              estado: 'SP',
              cep: '04512-001',
            })
            setBuscaPosition('')
          }}
        >
          Load Bairros
        </button> */}

      <GoogleMap
        mapContainerStyle={{width: '100%', height: height}}
        center={center}
        zoom={18}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{
          // mapId: 'a28cbce2ae82f5e3',
          mapTypeControl: true,
          mapTypeControlOptions: {
            mapTypeIds: [
              'roadmap',
              'satellite',
              'hybrid',
              'noturno',
              'comercios_map',
              'onibus_map',
              'ligth',
              'simples',
              // 'a28cbce2ae82f5e3',
            ],
            style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
            position: google.maps.ControlPosition.LEFT_TOP,
          },
        }}
      >
        <div className={buscaPosition}>
          <Autocomplete
            onLoad={onLoadAutoComplete}
            onPlaceChanged={() => {
              onPlaceChanged(map)
            }}
          >
            <input
              type='text'
              placeholder='Digite um endereço...'
              className='form-control'
              style={{
                boxSizing: `border-box`,
                border: `1px solid transparent`,
                width: `48%`,
                height: `32px`,
                padding: `18px`,
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                outline: `none`,
                textOverflow: `ellipses`,
                position: 'absolute',
                left: '200px',
                top: '11px',
              }}
            />
          </Autocomplete>
        </div>
        <Tooltip hoverInfo={hoverInfo}></Tooltip>
        <PainelSelecionados selecionados={lotesSelecionados}></PainelSelecionados>
      </GoogleMap>
    </>
  )
}

export default MapView
