import {useEffect, useRef, useState} from 'react'
import {Explorer} from '../../Explorer'
import {Layout} from '../../Layout'
import { BiCheck, BiCopy } from 'react-icons/bi'

type PropsContextMenu = {
  showHide: boolean
  latLng: google.maps.LatLng | null
}

export const ContexMenu: React.FC<PropsContextMenu> = ({showHide, latLng}) => {
  const show = useRef<boolean>()
  show.current = showHide
  const [copy,setCopy] = useState(false)

  // useEffect(()=>{
  //   setCopy(false)
  // },[setCopy])
  function onClickTerritorioEmDados() {
    Layout.setShowTerritorioMenu(true, latLng)
    Layout.setShowContextMenu(false)
    Layout.setShowGeometryMenu(false)
  }

  function onCopyLocation(text) {
    navigator.clipboard.writeText(text)
    setCopy(true)
    return false
  }

  function onClickAddMarker() {
    const lng = latLng.lng()
    const lat = latLng.lat()
    Explorer.addMarker(lat, lng, 'Local')
    Layout.setShowContextMenu(false)
    Layout.setShowGeometryMenu(false)
  }

  function hideMenuOnClick(){
    Layout.setShowContextMenu(false)
    Layout.setShowGeometryMenu(false)

  }
  function hideMenuOnClickAVM(){
    Layout.setShowContextMenu(false)
    Layout.setShowGeometryMenu(false)
    Layout.setShowContextAVM(true,latLng)
    

  }

console.log(Layout)
  
  // eslint-disable-next-line no-script-url
  const url = '#'

  return (latLng ? 
    <>
      <div>
        <ul
          className={`dropdown-menu dropdown-context show`}
          onClick={(e) => {
            
            e.stopPropagation()
            //console.log('Click na ul do context')
          }}
        >
          <li className='nav-header'>Mais Informações</li>
          <li>
            <a href={url} className='context-event' onClick={onClickTerritorioEmDados}>
              Território em dados
            </a>
          </li>
          <li>
            <a href={url} className='context-event'        
              data-bs-target='#modal-painel-imobiliario'
              data-bs-toggle='modal' onClick={hideMenuOnClick}>
              Painel de Mercado
            </a>
          </li>
          <li>
            <a href={url} className='context-event'        
              // data-bs-target='#kt_modal_avm'
              // data-bs-toggle='modal'
               onClick={hideMenuOnClickAVM} >
              AVM
            </a>
          </li>
          <li className='divider'></li>
          <li className='nav-header'>Operações</li>
          
          <li>
            <a href={url} className='context-event' onClick={onClickAddMarker}>
              Adicionar Marcador
            </a>
          </li>
          {/* <li>
            <a
              href={url}
              className='context-event'
              data-bs-target='#modal-isocrona'
              data-bs-toggle='modal'
            >
              Isócrona
            </a>
          </li>
          <li>
            <a
              href={url}
              className='context-event'
              data-bs-target='#modal-isocota'
              data-bs-toggle='modal'
            >
              Isócota
            </a>
          </li>
          <li>
            <a
              href={url}
              className='context-event'
              data-bs-target='#modal-raio'
              data-bs-toggle='modal'
            >
              Raio
            </a>
          </li> */}
          <li className='divider'></li>
          <li className='nav-header'>Local</li>
          <li>
            
            <a href={url} className='context-event' onClick={()=>{
              const text = latLng.lat().toFixed(5) +','+ latLng.lng().toFixed(5)
              onCopyLocation(text)
            }}>
            {copy ? <BiCheck className="fs-1 text-success"></BiCheck> : <BiCopy className="fs-1"></BiCopy>} {latLng.lat().toFixed(5)}, {latLng.lng().toFixed(5)} 
              
            </a>
          </li>
        </ul>
      </div>
    </>: <></>
  )
}
