import {zodResolver} from '@hookform/resolvers/zod'
import {useForm} from 'react-hook-form'
import {AiFormData, AiFormSchema} from './ai.schema'

export const useAi = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm<AiFormData>({
    resolver: zodResolver(AiFormSchema),
  })

  return {
    register,
    handleSubmit,
    setValue,
    errors,
  }
}
