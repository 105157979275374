import {useCallback, useState} from 'react'
import {CreateStyles} from '../../../../../../explorer/components/Map/Style/MapStyles'
import {formatAdressSeachGoogleMapa} from '../../../../../../explorer/components/common'
import {GoogleMapsOverlay} from '@deck.gl/google-maps'
// import {CreateStyles} from '../../../explorer/components/Map/Style/MapStyles'
// import {formatAdressSeachGoogleMapa} from '../../../explorer/components/common'

export type TIsOpen = {
  id: number
  open: boolean
}

const useMap = () => {
  const [map, setMap] = useState(null)
  const [deckOverlay, setDeckOverlay] = useState(null)
  const [isOpen, setIsOpen] = useState<TIsOpen>(null)

  const onLoad = useCallback(
    (map) => {
      setMap(map)
      const deckOverlayObj = new GoogleMapsOverlay({layers: []})
      deckOverlayObj.setMap(map)

      setDeckOverlay(deckOverlayObj)
      CreateStyles(map)
      changeStyle('simples', map)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    null
  )

  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])

  function changeStyle(style: string, map) {
    map.setMapTypeId(style)
  }

  function handleMarkerClick(id: number) {
    // if (isOpen.length > 0) {
    // if (isOpen.length === 1){

    //   isOpen[0] = {
    //     id: id,
    //     open: false,
    //   }
    //   let existItem = isOpen.findIndex((item) => item.id === id)
    //   if (existItem !== -1) {
    //     isOpen.splice(existItem, 1)
    //   } else {
    //     isOpen.push({
    //       id: id,
    //       open: true,
    //     })
    //   }
    //   setIsOpen([...isOpen])
    // } else {
    let open = {
      id: id,
      open: true,
    }
    setIsOpen(open)
  }

  function handleMarkerMOuseOut(id: number) {
    // let existItem = isOpenSingle.findIndex((item) => item.id === id)
    // if (existItem !== -1) {
    //   isOpenSingle.splice(existItem, 1)
    // }
    // setIsOpenSingle([...isOpenSingle])
  }

  async function getGeocoder(lat: number, lng: number) {
    const geocoder = new google.maps.Geocoder()
    const latLng = new google.maps.LatLng(lat, lng)
    return geocoder.geocode({location: latLng}, async function (results, status) {
      if (status === 'OK') {
        var place = results[0]
        var adr_elements = formatAdressSeachGoogleMapa(place)
        return adr_elements
      }
    })
  }

  return {
    onLoad,
    onUnmount,
    map,
    handleMarkerClick,
    isOpen,
    handleMarkerMOuseOut,
    getGeocoder,
    deckOverlay,
  }
}

export default useMap
