import {GetEstadoChosen} from './Estado'
import {GetCidadeChosen} from './Cidades'
import {useEffect, useState} from 'react'
import {ErrorMessageComponent} from '../../../../backoffice/components/UI/ErrorMessage'

interface GetEstadoCidadeChosenProps {
  SetValueCidade: any
  SetValueEstado: any
  defaultValueCidade?: any
  defaultValueEstado?: any
  classNameCity?: string // Opcional
  classNameEstado?: string // Opcional
  errorCity?: string // Opcional
  errorState?: string // Opcional
}

export function GetEstadoCidadeChosen({
  SetValueCidade,
  SetValueEstado,
  defaultValueEstado,
  defaultValueCidade,
  classNameCity,
  classNameEstado,
  errorCity,
  errorState,
}: GetEstadoCidadeChosenProps) {
  // Função implementação

  const [estado, setEstado] = useState(null)

  useEffect(() => {
    setEstado(estado)

    return () => {
      setEstado(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValueEstado])

  return (
    <>
      <div className={` ${classNameEstado || 'col-xs-12'}`}>
        <div className='form-group'>
          <label className='col-form-label'>Estado</label>
          <GetEstadoChosen
            SetValueEstado={(estado) => {
              setEstado(estado)
              SetValueEstado(estado)
            }}
            defaultValueEstado={defaultValueEstado}
          ></GetEstadoChosen>
          {errorState && <ErrorMessageComponent message={errorState} />}
        </div>
      </div>
      <div className={` ${classNameCity || 'col-xs-12'}`}>
        <div className='form-group'>
          <label className='col-form-label'>Cidade</label>
          <GetCidadeChosen
            disabled={estado ? false : true}
            SetValueCidade={(cidade) => {
              console.log('cidade')
              console.log(cidade)
              SetValueCidade(cidade)
            }}
            defaultValueCidade={defaultValueCidade}
            estado={estado}
          ></GetCidadeChosen>
          {errorCity && <ErrorMessageComponent message={errorCity} />}
        </div>
      </div>
    </>
  )
}
