import {useEffect, useState} from 'react'
import {useLocation} from 'react-router'
import {DrawerComponent} from '../../../_metronic/assets/ts/components'
import {Footer} from '../../../_metronic/layout/components/Footer'
import {AsideDefault} from '../../../_metronic/layout/components/aside/AsideDefault'
import {HeaderWrapperFluid} from '../../../_metronic/layout/components/header/HeaderWrapperFluid'
import {TableInteligenciaMercado} from './InteligenciaMercado/TableInteligenciaMercado'
import {GetAcessoUsuarioProduto} from '../../utils/PermissoesAcesso'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

export function InteligenciaMercado() {
  const location = useLocation()
  useEffect(() => {
    DrawerComponent.hideAll()
  }, [location])

  const [filter, setFilter] = useState([])

  function handleInputChange(event) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {name, value} = event.target

    const filterArray = []

    if (value) {
      filterArray.push({
        field: ['nome'],
        operator: 'LIKE',
        value: value,
      })
    }

    setFilter(filterArray)
  }

  // let href = '#'
  const permissao = GetAcessoUsuarioProduto(66)

  
  return (
    <>
      <AsideDefault />
      <HeaderWrapperFluid title='Inteligência de Mercado' />

      {/* <Inteligencia Mercado /> */}
      <div className='wrapper d-flex flex-column flex-row-fluid'>
        <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
          <div className='post d-flex flex-column-fluid' id='kt_post'>
            {!permissao ? (
              <div className='content  d-flex flex-column flex-column-fluid' id='kt_content'>
                <div className='card-form px-4 py-12 mb-6' style={{height: '74vh'}}>
                  <div id='kt_content_container' className='container-fluid'>
                    <div className='alert alert-warning'>
                      <h3 className='pt-2 ps-2'>
                        Você não tem este produto contratado, por favor entre em contato no email:{' '}
                        <a href='mailto:contato@urbit.com.br?subject=Sobre Avaliação de Imóveis&body=Gostaria de informações sobre o produto de avaliação de imóveis.'>
                          contato@urbit.com.br
                        </a>
                      </h3>
                      {/* <MensagemAlertAcesso mensagem='Somente para assinantes do Produto - Urbit Pricing.'></MensagemAlertAcesso> */}
                    </div>
                    <div className='d-flex justify-content-center align-content-center'>
                      <img src={toAbsoluteUrl('/media/print-im.png')} alt='print-im' />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}

            {permissao ? (
              <div id='kt_content_container' className='container-fluid'>
                <div
                  className='py-6  d-flex align-items-center justify-content-between'
                  id='kt_header_container'
                >
                  <div
                    className='page-title d-flex flex-column align-items-start justify-content-center flex-wrap mt-n5 mt-lg-0 me-lg-2 pb-2 pb-lg-0'
                    data-kt-swapper='true'
                    data-kt-swapper-mode='prepend'
                    data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}"
                  >
                    <h1 className='text-dark fw-bold my-0 fs-2'>Meus Projetos</h1>
                    <ul className='breadcrumb fw-semibold fs-base my-1'>
                      <li className='breadcrumb-item text-muted'>
                        <a href='/' className='text-muted'>
                          Dashboard
                        </a>
                      </li>
                      <li className='breadcrumb-item text-muted'>Inteligência de Mercado</li>
                    </ul>
                  </div>
                  <div className='d-flex align-items-center ms-3'>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Pesquisar nome do projeto'
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className='content  d-flex flex-column flex-column-fluid' id='kt_content'>
                  <TableInteligenciaMercado filter={filter} />
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}
