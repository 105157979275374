import {Button, Col, Form, Row} from 'react-bootstrap'
import useLayoutControl from '../../../../../hooks/useLayoutControl'
import {AbrirTese} from './components/AbrirTese'
import {NovaTese} from './components/NovaTese'
import {GetEstadoCidadeChosen} from '../../../../../../../components/UI/Chosens/GetEstadoCidadeChosen'
import {useTese} from '../../../../../hooks/useTese'
import {GetBairrosChosen} from '../../../../../../../components/UI/Chosens/GetBairroChosen'
import {useState} from 'react'
import {GetZonaUsoChosen} from '../../../../../../../components/UI/Chosens/GetZonaUsoChosen'
import {GetTipoUsoChosen} from '../../../../../../../components/UI/Chosens/GetTipoUsoChosen'
import {ErrorMessageComponent} from '../../../../../../../backoffice/components/UI/ErrorMessage'
import { useDataTese } from '../../../../../context/DataContext'

type Props = {
  height: number
  setActiveTab:any
}

export const TabTese: React.FC<Props> = ({height,setActiveTab}) => {
  const {windowHeight} = useLayoutControl()
  const {handleSubmit, errors, register, setValue} = useTese()
  const [cidade, setCidade] = useState(null)
  const {setFilter} = useDataTese()
  function onsubmit(data) {
    setFilter({...data})
      setActiveTab('mapa')
    return false
  }
  
  return (
    <div>
      <div className='wrapper d-flex flex-column flex-row-fluid p-10'>
        <div className='content p-0 d-flex flex-column flex-column-fluid'>
          <div className='container-fluid p-0 border-1 border-radius'>
            <div className='row mb-5'>
              <div className='col-6'>
                <AbrirTese></AbrirTese>
              </div>
              <div className='col-6 text-end'>
                <NovaTese></NovaTese>
              </div>
            </div>
            <div style={{height: windowHeight.h}}>
              <div className='card mb-6'>
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-12'>
                      <Form onSubmit={handleSubmit(onsubmit)}>
                        <Row className='mb-3'>
                          <Form.Group as={Col} controlId='formGridEmail'>
                            <Form.Label>Nome</Form.Label>
                            <Form.Control
                              type='text'
                              placeholder='nome da tese'
                              aria-label='Informe o nome da tese'
                              name='nome'
                              {...register('tese', {required: true})}
                            />
                            <ErrorMessageComponent
                              message={errors ? errors.tese?.message : ''}
                            ></ErrorMessageComponent>
                          </Form.Group>
                        </Row>
                        <Row className='mb-3'>
                          <GetEstadoCidadeChosen
                            // defaultValueEstado={26}
                            // defaultValueCidade={5351}
                            classNameCity={'col-4 col-xs-12 mt-1'}
                            classNameEstado={'col-4 col-xs-12  mt-1'}
                            SetValueEstado={(value) => {
                              setValue('idEstado', value)
                            }}
                            SetValueCidade={(value) => {
                              setCidade(value)
                              setValue('idCidade', value)
                            }}
                            errorCity={errors ? errors.idCidade?.message : ''}
                            errorState={errors ? errors.idEstado?.message : ''}
                          ></GetEstadoCidadeChosen>

                          <Form.Group as={Col} controlId='formPlaintextEmail' className='col-4'>
                            <GetBairrosChosen
                              setValue={(value) => {
                                setValue('idBairro', value)
                              }}
                              value={null}
                              idCidade={cidade}
                            ></GetBairrosChosen>
                            <ErrorMessageComponent
                              message={errors ? errors.idBairro?.message : ''}
                            ></ErrorMessageComponent>
                          </Form.Group>
                        </Row>

                        <Row className='mb-3'>
                          <Form.Group as={Col} controlId='formPlaintextEmail'>
                            <GetZonaUsoChosen
                              setValue={(value) => {
                                setValue('zonaDeUso', value)
                              }}
                              value={null}
                            ></GetZonaUsoChosen>
                            <ErrorMessageComponent
                              message={errors ? errors.zonaDeUso?.message : ''}
                            ></ErrorMessageComponent>
                          </Form.Group>
                        </Row>

                        <Row className='mb-3'>
                          <Form.Group as={Col} className='mb-3' controlId='formPlaintextEmail'>
                            <Form.Label>Área Mínima do lote</Form.Label>
                            <Form.Control
                              type='number'
                              placeholder='área m²'
                              aria-label='Selecionar a área'
                              name='area_minima'
                              {...register('areaMinimaLote', {required: true, valueAsNumber: true})}
                            />
                            <ErrorMessageComponent
                              message={errors ? errors.areaMinimaLote?.message : ''}
                            ></ErrorMessageComponent>
                          </Form.Group>
                          <Form.Group as={Col} className='mb-3' controlId='formPlaintextEmail'>
                            <Form.Label>Área Mínima terreno</Form.Label>

                            <Form.Control
                              type='text'
                              placeholder='área m²'
                              aria-label='Selecionar a área'
                              {...register('areaMinimaTerreno', {
                                required: true,
                                valueAsNumber: true,
                              })}
                            />
                            <ErrorMessageComponent
                              message={errors ? errors.areaMinimaTerreno?.message : ''}
                            ></ErrorMessageComponent>
                          </Form.Group>

                          <Form.Group as={Col} className='mb-3' controlId='formPlaintextEmail'>
                            <Form.Label>Área Máxima terreno</Form.Label>

                            <Form.Control
                              type='text'
                              placeholder='área m²'
                              aria-label='Selecionar a área'
                              {...register('areaMaximaTerreno', {
                                required: true,
                                valueAsNumber: true,
                              })}
                            />
                            <ErrorMessageComponent
                              message={errors ? errors.areaMaximaTerreno?.message : ''}
                            ></ErrorMessageComponent>
                          </Form.Group>
                        </Row>

                        <Row className='mb-3'>
                          <Form.Group as={Col} className='mb-3' controlId='formPlaintextEmail'>
                            <Form.Label>Pavimentos</Form.Label>

                            <Form.Control
                              type='number'
                              placeholder='0'
                              aria-label='Nº pavimentos'
                              {...register('pavimentos', {required: true, valueAsNumber: true})}
                            />
                            <ErrorMessageComponent
                              message={errors ? errors.pavimentos?.message : ''}
                            ></ErrorMessageComponent>
                          </Form.Group>
                          <Form.Group as={Col} className='mb-3' controlId='formPlaintextEmail'>
                            <GetTipoUsoChosen
                              setValue={(value) => {
                                setValue('tipoUso', value)
                              }}
                              value={null}
                            ></GetTipoUsoChosen>
                            <ErrorMessageComponent
                              message={errors ? errors.tipoUso?.message : ''}
                            ></ErrorMessageComponent>
                          </Form.Group>

                          <Form.Group as={Col} className='mb-3' controlId='formPlaintextEmail'>
                            <Form.Label>Proprietário único </Form.Label>

                            <div className='mb-10'>
                              <div className='form-check form-switch form-check-custom form-check-solid'>
                                <input
                                  className='form-check-input'
                                  {...register('proprietarioUnico', {required: true})}
                                  type='checkbox'
                                  value=''
                                  id='proprietarioUnico'
                                />
                                <label className='form-check-label' htmlFor='proprietarioUnico'>
                                  Sim
                                </label>
                              </div>
                            </div>
                          </Form.Group>
                        </Row>
                        <Button variant='primary' type='submit'>
                          Próximo
                        </Button>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
