import {Polygon} from '@react-google-maps/api'
import * as turf from '@turf/turf'
import {useCallback, useContext, useEffect, useRef, useState} from 'react'
import {FilterContext} from '../../../context/FilterContext'
import {CoordinatesToPath} from '../../Common/common'
import useImoveis from '../../../hooks/useImoveis'
import {DrawingManager} from '@react-google-maps/api'
import {FaHandFist} from 'react-icons/fa6'
import {BiPolygon, BiTrash} from 'react-icons/bi'

const options = {
  fillColor: 'lightgreen',
  fillOpacity: 0.6,
  strokeColor: 'green',
  strokeOpacity: 1,
  strokeWeight: 1,
  clickable: true,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
}

type Props = {
  map: any
}

const optionsEditable = {
  fillColor: 'lightred',
  fillOpacity: 0.6,
  strokeColor: 'red',
  strokeOpacity: 1,
  strokeWeight: 1,
  clickable: true,
  draggable: true,
  editable: true,
  geodesic: false,
  zIndex: 1,
}

function DefinirManualmente({map}: Props) {
  const {setGeometria, geometria, setGeometriaSelecionada} = useContext(FilterContext)
  const [paths, setPaths] = useState(null)
  const drawingManager = useRef<any>()
  const {loadImoveis, clearData} = useImoveis()
  const polygonRef = useRef(null)
  const listenersRef = useRef([])

  // useEffect(() => {
  //   console.log('Conponete DefinirManualmente ')
  // }, [])

  useEffect(() => {
    if (map) {
      map.addListener('click', onClickMap)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map])

  useEffect(() => {
    if (geometria && map) {
      let GeoJSON: any = {}
      if (geometria.type === 'Feature') {
        GeoJSON = geometria
      } else {
        GeoJSON = {
          type: 'Feature',
          geometry: geometria,
          properties: {area: '0'},
        }
        loadDefineirManualmente(GeoJSON)

        setGeometria(geometria)
        setGeometriaSelecionada(GeoJSON)
      }

      map.setZoom(15)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geometria, map])

  function onClickMap(e) {
    if (paths) {
      if (paths.editable) {
        paths.editable = false

        loadImoveis(
          paths.geojson.geometry,
          'definirManualmente',
          (item) => {
            // console.log(item)
          },
          paths.geojson
        )

        setPaths({...paths})
      }
    }
  }

  function onLoadDraw(drawingManagerInstance) {
    drawingManager.current = drawingManagerInstance
    drawingManager.current.setMap(map)
  }

  const onPolygonComplete = (polygon) => {
    let GeoJSON = {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [],
      },
      properties: {area: '0'},
    }

    const firstPath = polygon.getPath().getArray()[0]

    let coordenadas = []
    for (let point of polygon.getPath().getArray()) {
      coordenadas.push([point.lng(), point.lat()])
    }
    coordenadas.push([firstPath.lng(), firstPath.lat()])
    GeoJSON.geometry.coordinates.push(coordenadas)

    loadDefineirManualmente(GeoJSON)
    drawingManager.current.setDrawingMode('')
    setGeometria(GeoJSON.geometry)
    setGeometriaSelecionada(GeoJSON)
    map.setZoom(15)
    polygon.setMap(null)
  }

  function loadDefineirManualmente(GeoJSON) {
    loadImoveis(
      GeoJSON.geometry,
      'definirManualmente',
      (item) => {
        console.log(item)
      },
      GeoJSON
    )

    const pathsTemp = {
      path: CoordinatesToPath(GeoJSON.geometry.coordinates[0]),
      center: turf.centroid(GeoJSON.geometry),
      geojson: GeoJSON,
      editable: false,
    }

    setPaths({...pathsTemp})
  }

  function onClickClear() {
    // paths.setMap(null)
    setPaths(null)
    clearData()
  }

  function onClickDragMap() {
    if (drawingManager.current) {
      drawingManager.current.setDrawingMode('')
    }
  }

  function onClickDraw() {
    if (drawingManager.current) {
      drawingManager.current.setDrawingMode('polygon')
    }
  }

  const onEdit = useCallback(
    (el) => {
      if (polygonRef.current) {
        // const nextPath = polygonRef.current
        //   .getPath()
        //   .getArray()
        //   .map((latLng) => {
        //     return {lat: latLng.lat(), lng: latLng.lng()}
        //   })

        let GeoJSON = {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [],
          },
          properties: {area: '0'},
        }

        const firstPath = polygonRef.current.getPath().getArray()[0]

        let coordenadas = []
        for (let point of polygonRef.current.getPath().getArray()) {
          coordenadas.push([point.lng(), point.lat()])
        }
        coordenadas.push([firstPath.lng(), firstPath.lat()])
        GeoJSON.geometry.coordinates.push(coordenadas)

        const pathsTemp = {
          path: CoordinatesToPath(GeoJSON.geometry.coordinates[0]),
          center: turf.centroid(GeoJSON.geometry),
          geojson: GeoJSON,
          editable: false,
        }
        setPaths({...pathsTemp})
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setPaths]
  )

  const onLoadPoligon = useCallback(
    (polygon) => {
      polygonRef.current = polygon
      const path = polygon.getPath()
      listenersRef.current.push(
        path.addListener('set_at', onEdit),
        path.addListener('insert_at', onEdit),
        path.addListener('remove_at', onEdit)
      )
    },
    [onEdit]
  )

  //TODO Aplicar edição ao double click
  // function dbClick(e, elementMap) {
  //   // eslint-disable-next-line array-callback-return
  //   paths.editable = true
  //   map.setZoom(15)
  //   setPaths({...paths})
  // }

  return (
    <>
      <DrawingManager
        onLoad={onLoadDraw}
        onPolygonComplete={onPolygonComplete}
        options={{
          drawingControl: false,
          drawingControlOptions: {
            position: google.maps.ControlPosition.BOTTOM_CENTER,
            drawingModes: [google.maps.drawing.OverlayType.POLYGON],
          },

          polylineOptions: {
            visible: false,
          },
          markerOptions: {
            visible: false,
          },
          polygonOptions: {
            fillColor: `#20a745`,
            fillOpacity: 0.6,
            strokeWeight: 2,
            clickable: false,
            editable: true,
            zIndex: 1,
          },
        }}
      />
      <div
        className='d-flex d-flex-row pb-0 w-200px justify-content-around bg-light-primary text-primary p-3 pb-4 rounded border-radius-10'
        style={{position: 'absolute', bottom: 34, left: '40%'}}
      >
        <div
          className='p-2 border-1 bg-hover-primary text-hover-inverse-primary border-radius-2 border-gray-200 rounded'
          onClick={onClickDragMap}
        >
          <FaHandFist className='fs-1' />
        </div>
        <div
          className='p-2 border-1 bg-hover-primary text-hover-inverse-primary border-radius-2 border-gray-200 rounded'
          onClick={onClickDraw}
        >
          <BiPolygon className='fs-1' />
        </div>
        <div
          className='p-2 border-1 bg-hover-primary text-hover-inverse-primary border-radius-2 border-gray-200 rounded'
          onClick={onClickClear}
        >
          <BiTrash className='fs-1' />
        </div>
      </div>

      {paths ? (
        <Polygon
          onLoad={onLoadPoligon}
          paths={paths.path}
          key={paths.id}
          options={paths.editable ? optionsEditable : options}
          onDblClick={(e) => {
            // dbClick(e, paths)
          }}
          draggable={false}
          onMouseUp={onEdit}
        />
      ) : (
        ''
      )}
    </>
  )
}

export default DefinirManualmente
