import {useEffect, useState} from 'react'
import Select from 'react-select'
import {FormLabel} from 'react-bootstrap'
import {useUso} from './useUso'

export function GetTipoUsoChosen({setValue, value}: {setValue: any; value: any}) {
  let zonaUsosArray = []
  const [usoSelected, setUsoSelected] = useState(null)
  const {uso, setUso, fetchZonaUso} = useUso()

  useEffect(() => {
    setUso(value)
    
  }, [setUso, value])

  useEffect(() => {
    fetchZonaUso()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!uso) {
    return (
      <>
        <div className='form-group'>
          <FormLabel className='form-label'>Tipos de Uso</FormLabel>
          <Select
            options={zonaUsosArray}
            onChange={(e) => {
              setUso(e.value)
            }}
            value={zonaUsosArray.filter((e) => e.value)[0]}
            name='id_zonaUso'
            placeholder='zonaUsos Disponiveis'
          />
        </div>
      </>
    )
  }
  zonaUsosArray.push({value: '', label: '--selecione--'})
  uso.map((options: any) => {
    zonaUsosArray.push({value: options, label: options})
    return []
  })

  function containsAny(arr1: any[], arr2: string | any[]) {
    return arr1.filter((item: { value: any; }) => {
      return arr2.includes(item.value)
    })
  }
  return (
    <>
      <div className='form-group'>
        <FormLabel className='form-label'>Tipos de Uso</FormLabel>
        <Select
          isMulti={true}
          value={
            usoSelected
              ? containsAny(zonaUsosArray, usoSelected ? usoSelected.map((item) => item.value) : [])
              : zonaUsosArray.filter((item) => item.value === value)
          }
          options={zonaUsosArray}
          onChange={(itens: []) => {
            setUsoSelected(itens)
            setValue(
              itens.map((opt: {value: string; label: string}) => {
                return opt.value
              })
            )
          }}
          name='tipoUso'
          placeholder='Tipo Uso'
        />
      </div>
    </>
  )
}
