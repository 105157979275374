import {AsideDefault} from '../../../../_metronic/layout/components/aside/AsideDefault'
import MenuTopo from './partials/MenuTopo'
import {Tabs} from './partials/components/Tabs/Tabs'
import useLayoutControl from '../hooks/useLayoutControl'

export function Prospeccao() {
  // const {id: idProjetoURL} = useParams<{id: string}>()
  const {windowHeight} = useLayoutControl()

  return (
    <>
        <AsideDefault />
        <MenuTopo nome={'teste'} tipo={'venda'} />
        <Tabs windowHeight={windowHeight}></Tabs>
    </>
  )
}
