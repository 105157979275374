import {useRef, useState} from 'react'
import Button from 'react-bootstrap/Button'
import FormControl from 'react-bootstrap/FormControl'
import Modal from 'react-bootstrap/Modal'
import {confirmable, createConfirmation} from 'react-confirm'
import {GetEmpreendimentoSituacaoChosen} from './Chosens/GetEmpreedimentoSituacao'

const ComplexConfirmation = ({show, proceed, dismiss, cancel, message, title}) => {
  const inputRef = useRef()
  const [situacao, setSituacao] = useState()
  const handleOnClick = (index) => {
    return () => {
      proceed({
        button: index,
        input: inputRef.current ? inputRef.current['value'] : null,
        select: situacao,
      })
    }
  }

  return (
    <div className='static-modal'>
      <Modal animation={false} show={show} onHide={dismiss}>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {message}
          <FormControl type='text' ref={inputRef} />
          <GetEmpreendimentoSituacaoChosen
            SetValueSituacao={(value) => {
              setSituacao(value)
            }}
            valueSituacao={situacao || null}
          ></GetEmpreendimentoSituacaoChosen>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => proceed(false)} data-dismiss='modal' className='btn btn-secondary'>
            Cancel
          </Button>
          <Button className='button-l' onClick={handleOnClick(1)}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export const confirm = createConfirmation(confirmable(ComplexConfirmation))
