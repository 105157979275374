import {useContext, useEffect, useState} from 'react'
import {AiChat} from '../../../../components/AiChat'
import {DataContext} from '../../context/DataContext'
import {HeaderPerformance} from '../Common/HeaderPerformance'
import {formatNumber} from '../../../../components/util'
import {formatDate} from '../../../explorer/components/common'
import {ItensImoveisProps, Tipologia} from '../Types'

export function Ai({id}: {id: number}) {
  const {data, cacheResult} = useContext(DataContext)
  const [csvContent, setCsvContent] = useState(null)

  const dataToExport = []
  useEffect(() => {
    if (data) {
      dataToExport.push([
        'Situação',
        'Nome',
        'Endereço',
        'Nome Incorporador',
        'Data Lançamento',
        'Área Privativa',
        'Vagas',
        'Suítes',
        'Dormitórios',
        'Tipo',
        'Preço Inicial',
        'Preço Inicial m²',
        'Preço Estoque',
        'Preço Estoque m²',
        'Total Unidades',
        'Estoque',
        'Vendas',
        '% Vendas',
        'Data Preço',
      ])

      // eslint-disable-next-line array-callback-return
      data.map((item: ItensImoveisProps, index) => {
        // eslint-disable-next-line array-callback-return
        item.tipologias.map((itemTipologia: Tipologia) => {
          if (itemTipologia.computed) {
            dataToExport.push([
              item.situacao,
              item.nome,
              item.endereco,
              item.nome_incorporador,
              formatDate(item.data_lancamento),
              itemTipologia.private_area ? formatNumber(itemTipologia.private_area, 'decimal') : 0,
              itemTipologia.parking,
              itemTipologia.suites,
              itemTipologia.bedrooms,
              itemTipologia.type,
              itemTipologia.preco_lancamento
                ? formatNumber(itemTipologia.preco_lancamento, 'currency')
                : '0',
              itemTipologia.preco_lancamento
                ? formatNumber(
                    itemTipologia.preco_lancamento / itemTipologia.private_area,
                    'currency'
                  )
                : 0,
              formatNumber(itemTipologia.original_price, 'currency'),
              itemTipologia.private_area > 0
                ? formatNumber(
                    itemTipologia.original_price / itemTipologia.private_area,
                    'currency'
                  )
                : 0,
              itemTipologia.total_units,
              itemTipologia.stock,
              itemTipologia.total_units - itemTipologia.stock,
              itemTipologia.vendas_perc_tip,
              formatDate(item.data_preco),
              // item.webpage,
            ])
          }
        })
      })

      var lineArray = []
      dataToExport.forEach(function (infoArray, index) {
        // console.log(infoArray)
        var line = infoArray.join(';')
        lineArray.push(index === 0 ? '' + line : line)
        // lineArray.push(index === 0 ? 'data:text/csv;charset=utf-8,' + line : line)
      })
      var csvContent = lineArray.join('\n')

      setCsvContent(csvContent)
      // var line = `${item.situacao}`;
      // lineArray.push(index === 0 ? "data:text/csv;charset=utf-8," + line : line);
    }
  }, [data])

  // console.log(csvContent)
  // console.log(data)
  // console.log(cacheResult)

  let metro = ''
  let metroPlanejado = ''
  let areaVulnerabilidade = ''
  let educacao = ''
  let sociodemografico = ''

  // eslint-disable-next-line array-callback-return
  cacheResult.sociodemografico.map((item) => {
    let sociodemografico = ''

    if (item.servico === 'metro-planejado') {
      if (item?.resultado?.itens.length > 0) {
        item.resultado.itens.map((itens) => {
          metroPlanejado += ` 
                Linha: ${itens.linha}\n
                Estação: ${itens.nome_estacao}\n
                Situação da Linha: ${itens.nome_estacao}\n
                Tecnologia: ${itens.nome_estacao}\n
                Distância: ${itens.distancia}\n
                _ 
              `
        })
      }
    }

    if (item.servico === 'metro') {
      if (item?.resultado?.itens.length > 0) {
        // eslint-disable-next-line array-callback-return
        item.resultado.itens.map((itens) => {
          metro += ` 
                Linha: ${itens.linha}\n
                Estação: ${itens.nome_estacao}\n
                Situação da Linha: ${itens.nome_estacao}\n
                Tecnologia: ${itens.nome_estacao}\n
                Distância: ${itens.distancia}\n
                _
              `
        })
      }
    }

    if (item.servico === 'area-vulnerabilidade') {
      if (item?.resultado?.itens.length > 0) {
        // eslint-disable-next-line array-callback-return
        item?.resultado?.itens.map((itens) => {
          areaVulnerabilidade += ` 
                Área HA: ${itens.area_ha}\n
                Municipio: ${itens.municipio}\n
                Nome: ${itens.nome}\n
                Distância: ${itens.distancia}\n
                _
              `
        })
      }
    }

    if (item.servico === 'equipamentos-educacao') {
      if (item?.resultado?.itens.length > 0) {
        // eslint-disable-next-line array-callback-return
        item.resultado.itens.map((itens) => {
          educacao += ` 
                Nome: ${itens.nome}\n
                Administração: ${itens.administracao}\n
                Endereço: ${itens.endereco}\n
                Ensinos: ${itens.ensinos}\n
                Matriculas: ${itens.matriculas}\n
                Distância: ${itens.distancia}\n
                _
              `
        })
      }
    }
    if (item.servico === 'renda-domiciliar-intersect') {
      if (item?.resultado?.itens.length > 0) {
        // eslint-disable-next-line array-callback-return
        item.resultado.itens.map((itens) => {
          sociodemografico += ` 
          População:${itens.pop_total}\n
          Domicílios: ${itens.dom_total}\n
          Renda média mensal domiciliar: R$ ${itens.renda_mensal_domicilio}\n
          Percentual de Domicílios Alta Renda: ${formatNumber(
            Number(itens.perc_dom_alta_renda),
            'inteiro'
          )}\n
          Percentual de Domicílios baixa Renda: : ${formatNumber(
            Number(itens.perc_dom_baixa_renda),
            'inteiro'
          )}\n
          Pontecial de consumo mensal: ${formatNumber(
            Number(itens.potencial_de_consumo_mensal) * 100,
            'currency_inteiro'
          )}\n
          _
              `
        })
      }
    }
  })

  let dadosDemograficos = `${sociodemografico} `

  let dadosInfra = `
  Metrô: ${metro} \n
  Metrô planejado: ${metroPlanejado} \n
  Área de Vulnerabilidade: ${areaVulnerabilidade} \n
  Educação: ${educacao} \n`
  // console.log(metroPlanejado)
  // console.log(metro)
  // console.log(areaVulnerabilidade)
  // console.log(dadosDemograficos)
  let contextoInicial = `

        #dadosSocioDemograficos 
        ${dadosDemograficos}

        #infraestrutura 
        ${dadosInfra}

        #dadosDeLancamentos
        ${csvContent}
`

  // console.log(contextoInicial)
  //   let contextoInicial  = `A população total da área de influência é de ${pop_total}, o número de domicílios de ${dom_total}, sendo ${casa_total} casas e ${apto_total} apartamentos,
  // sendo ${dom_alta_renda} domicílios de alta renda e ${dom_baixa_renda2} domicílios de baixa renda, ${dom_alugado} são domicílios alugados e ${dom_quitado} são quitados.
  // A renda média domiciliar é de ${renda_mensal_domiciliar}, sendo que a média da cidade é de R$ 4.500,00 por mês,
  // A população jovem abaixo de 14 anos é de ${pop_10_14} e a idosa é de ${pop_65_mais}.`

  return (
    <>
      <div className=' d-flex flex-column justify-content-start p-10'>
        <div className='header-report  '>
          <div className='d-flex flex-row all-start-between'>
            <div className=' '>
              <h1 className='fw-bolder'>Urbit AI</h1>
            </div>
          </div>
        </div>
        {/* <HeaderPerformance titulo={'Urbit AI'} showSubHeader={false}></HeaderPerformance> */}
        <div className='content pt-5'>
          <div className='row g-5 g-xl-8'>
            <AiChat persona={'especilistaIM'} dadosAnalise={contextoInicial}></AiChat>
          </div>
        </div>
      </div>
    </>
  )
}
