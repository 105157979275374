import {useEffect, useState} from 'react'
import Select from 'react-select'
import {FormLabel} from 'react-bootstrap'
import {useZonaUso} from './useZonaUso'

type Options = {
  value: string
  label: string
}

export function GetZonaUsoChosen({setValue, value}: {setValue: any; value: any}) {
  let zonaUsosArray = []
  const [zonaUsoSelected, setZonaUsoSelected] = useState(null)
  const {zonaUso, setZonaUso, fetchZonaUso} = useZonaUso()

  useEffect(() => {
    setZonaUso(value)
  }, [setZonaUso, value])

  useEffect(() => {
    fetchZonaUso()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!zonaUso) {
    return (
      <>
        <div className='form-group'>
          <FormLabel className='form-label'>Zone de uso</FormLabel>
          <Select
            options={zonaUsosArray}
            onChange={(e: Options) => {
              setZonaUso(e.value)
            }}
            value={zonaUsosArray.filter((e: Options) => e.value)[0]}
            name='id_zonaUso'
            placeholder='zonaUsos Disponiveis'
          />
        </div>
      </>
    )
  }
  zonaUsosArray.push({value: '', label: '--selecione--'})
  zonaUso.map((options: {zona: any}) => {
    zonaUsosArray.push({value: options.zona, label: options.zona})
    return []
  })

  function containsAny(arr1: any[], arr2: string | any[]) {
    return arr1.filter((item) => {
      return arr2.includes(item.value)
    })
  }

  return (
    <>
      <div className='form-group'>
        <FormLabel className='form-label'>Zone de uso</FormLabel>
        <Select
          isMulti={true}
          value={
            zonaUsoSelected
              ? containsAny(
                  zonaUsosArray,
                  zonaUsoSelected ? zonaUsoSelected.map((item: Options) => item.value) : []
                )
              : zonaUsosArray.filter((item: Options) => item.value === value)
          }
          options={zonaUsosArray}
          onChange={(itens: []) => {
            setZonaUsoSelected(itens)
            setValue(itens.map((opt: Options) => opt.value))
          }}
          name='id_zonaUso'
          placeholder='zonaUsos'
        />
      </div>
    </>
  )
}
