import {BiTrash} from 'react-icons/bi'
import {useDataTese} from '../../../../../context/DataContext'

export const PainelSelecionados = ({selecionados}) => {
  const {removeItemLotesSelecionados} = useDataTese()
  function remove(item) {
    removeItemLotesSelecionados(item)
  }
  return (
    <div className='painel-prospecacao p-0 pt-4 '>
      <p className='p-2'><span>Área Total: </span> {selecionados.reduce((acumulador,current ) => { return acumulador = acumulador + Number(current.qt_area_te) },0 )} m²</p>
      <table className='table table-striped gy-1 g-2'>
        <thead>
          <tr>
            <th>Logradouro</th>
            <th className="text-nowrap">Contribuinte</th>
            <th className="text-nowrap">Área</th>
          </tr>
        </thead>
        <tbody>
          {selecionados &&
            selecionados.map((hoverInfo) => {
              return (
                <tr>
                  <td>{hoverInfo.nm_logrado}</td>
                  <td>{hoverInfo.numero_contribuinte}</td>
                  <td>{hoverInfo.qt_area_te}m²</td>
                  <td>
                    {/* <button className='btn btn-danger btn-icon p-2'> */}
                      <BiTrash className='fs-1'  onClick={() => remove(hoverInfo.numero_contribuinte.substring(0, 10))} color="red"></BiTrash>
                    {/* </button> */}
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>
    </div>
  )
}
