import {Nav} from 'react-bootstrap'
import {Scrollbars} from 'react-custom-scrollbars-2'
import { useDataTese } from '../../../../context/DataContext'


export function TabItens({windowHeight, setActiveTab,activeTab}) {
  const {endereco} = useDataTese()

  return (
    <Scrollbars style={{width: '100%', height: windowHeight - 110}}>
      <Nav variant='pills' className='flex-column m-0 nav-im'>
      <Nav.Item className='m-0'>
          <Nav.Link
            eventKey='item0'
            className='fs-6'
            active={activeTab === 'teste' ? true :false}
            onClick={() => {
              setActiveTab('tese')
            }}
          >
            1. Tese
          </Nav.Link>
        </Nav.Item>

        <Nav.Item className='m-0'>
          <Nav.Link
            eventKey='item1'
            className='fs-6'
            active={activeTab === 'mapa' ? true :false}
            onClick={() => {
              setActiveTab('mapa')
            }}
          >
            1. Lotes/Áreas
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            eventKey='item2'
            className='fs-6'
            onClick={() => {
              setActiveTab('mapa2')
            }}
          >
            2. Definir empreendimento
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            eventKey='item3'
            className='fs-6'
            disabled={false
            }
            onClick={() => {
              setActiveTab('resumoMercado')
            }}
          >
            3. Relatorios
          </Nav.Link>
        </Nav.Item>
     
       
        <Nav.Item>
          <Nav.Link
            eventKey='item5'
            className='fs-6'
            disabled={endereco && endereco.latitude ? false : true}
            onClick={() => {
              setActiveTab('avm')
            }}
          >
            5. AVM<br></br>
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </Scrollbars>
  )
}
