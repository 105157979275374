import { z } from "zod"

export const TeseFormSchema = z.object({
    tese: z.string().min(1,'Nome da tese obrigatória.'),
    idEstado: z.number(),
    idCidade: z.number(),
    idBairro: z.array(z.string()),
    zonaDeUso: z.array(z.string()),
    areaMinimaTerreno:z.number(),
    areaMaximaTerreno:z.number(),
    areaMinimaLote:z.number(),
    pavimentos:z.number(),
    tipoUso:z.array(z.string()),
    proprietarioUnico:z.boolean(),
})

export type TeseFormData = z.infer<typeof TeseFormSchema>