/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {QueryGetEmpreendimentoSituacao} from '../../../../../graphql/services/EmpreendimentoSituacao'
import {BarChart} from './BarChart'
import {QueryGetEmpreendimentosRelatorioStatus} from '../../../../../graphql'
import {QueryGetEquipes} from '../../../../../graphql/services/Equipes'

type Props = {
  className: string
}

const TablePorSituacaoEmpreendimento: React.FC<Props> = ({className}) => {
  const {data: dataStatus} = QueryGetEmpreendimentoSituacao({
    variables: {
      pagination: {
        pagina: 0,
        quantidade: 100,
      },
    },
  })

  const {data: dataEquipes} = QueryGetEquipes({
    variables: {
      pagination: {
        pagina: 0,
        quantidade: 100,
      },
    },
  })

  const {data: dataRelatorio} = QueryGetEmpreendimentosRelatorioStatus({
    variables: {
      pagination: {
        pagina: 0,
        quantidade: 100,
      },
    },
  })

  if (!dataStatus) {
    return <div>'carregando...'</div>
  }
  if (!dataEquipes) {
    return <div>'carregando...'</div>
  }

  if (!dataRelatorio) {
    return <div>'carregando...'</div>
  }
  let dataReport = []
  let equipes = dataEquipes.GetEquipe.result.map((item) => item.nome)

  // eslint-disable-next-line array-callback-return
  dataStatus.GetEmpreendimentoSituacao.result.map((situacao) => {
    let dadosFinal = []
    dataEquipes.GetEquipe.result.map((equipe) => {
      let dataR = dataRelatorio.GetEmpreendimentosRelatorioStatusEmpreendimento.filter((item) => {
        if (equipe.id === item.id_equipe && situacao.id === item.id_status) {
          return item
        }
      }).map((item) => item.qtde)
      dadosFinal.push(dataR.length > 0 ? dataR[0] : 0)
      return dataR
    })

    dataReport.push({
      name: situacao.nome,
      data: dadosFinal,
    })
  })

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Empreendimentos </span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Equipe x Situação</span>
        </h3>
      </div>
      <div className='card-body py-3'>
        <BarChart
          className={''}
          title={''}
          subtitle={''}
          legenda={['']}
          data={dataReport}
          onChange={() => {}}
          categorias={equipes}
          series={dataReport}
        ></BarChart>

        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
            <thead>
              <tr className='border-0'>
                <th className='p-0 '></th>
                {dataStatus.GetEmpreendimentoSituacao.result.map((itens) => {
                  return <th className='p-0 text-center'>{itens.nome}</th>
                })}
              </tr>
            </thead>
            <tbody>
              {dataEquipes.GetEquipe.result.map((equipe) => {
                return (
                  <tr>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        {equipe.nome}-{equipe.id}
                      </a>
                    </td>
                    {
                      // eslint-disable-next-line array-callback-return
                      dataStatus.GetEmpreendimentoSituacao.result.map((situacao) => {
                        // eslint-disable-next-line array-callback-return
                        let statusEmp =
                          dataRelatorio.GetEmpreendimentosRelatorioStatusEmpreendimento.map(
                            // eslint-disable-next-line array-callback-return
                            (item) => {
                              if (equipe.id === item.id_equipe && situacao.id === item.id_status) {
                                return (
                                  <td className='text-center text-muted fw-semibold'>
                                    {item.qtde}
                                  </td>
                                )
                              }
                            }
                          )
                        let checkVazio = statusEmp.filter((item) => item)
                        return checkVazio.length > 0 ? (
                          statusEmp
                        ) : (
                          <td className='text-center text-muted fw-semibold'>0</td>
                        )
                      })
                    }
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export {TablePorSituacaoEmpreendimento}
