import {useContext, useEffect, useState} from 'react'
import {AsideDefault} from '../../../../_metronic/layout/components/aside/AsideDefault'
import MenuTopo from './MenuTopo'
import {Col, Tab} from 'react-bootstrap'
import {FilterContext} from '../context/FilterContext'
import {useLocation, useParams} from 'react-router-dom'
import {QueryGetInteligenciaMercadoByID} from '../../../graphql/services/inteligencia-mercado'
import {ModalDados} from './ModalDados'
import {Filtro} from '../components/Filtro/Filtro'
import Segmentacao from '../components/Segmentacao'
import MapViewGeojson from '../components/MapViewGeojson'

import {
  Performance,
  ResumoMercado,
  PerformanceSocioDemografico,
  PerformanceResumoMercado,
  PerformanceAnaliseConcorrentes,
  PerformanceVendas,
  PerformanceBrevesLancamentos,
  PerformancePontosInteresse,
  Avm,
} from '../components/Reports'
import {TabItens} from './components/TabsItens'
import {SplashScreenComponent} from '../../../components/UI'
import {DataContext} from '../context/DataContext'
import {debounce} from '../components/Common/common'
import {PerformanceDesempenhoVendas} from '../components/Reports/PerformanceDesempenhoVendas'
import {Ai} from '../components/Reports/Ai'
// import { debounce } from 'redux-saga/effects'

export function InteligenciMercado() {
  const {id: idProjetoURL} = useParams<{id: string}>()

  const {setFilterData, setEndereco, setGeometria, setDadosProjetoSalvo} = useContext(FilterContext)
  const {setNaoComputados} = useContext(DataContext)
  const {data: dataProjeto} = QueryGetInteligenciaMercadoByID({
    variables: {
      getInteligenciaMercadoById: parseInt(idProjetoURL),
    },
  })

  const [showMap, setShowMap] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [windowHeight, setWindowHeight] = useState({
    h: window.innerHeight - 90,
    w: window.innerWidth - 345,
  })
  const [activeTab, setActiveTab] = useState('zonaDeValor')
  function handleShowPanel() {
    setShowMap(false)
  }

  // SET DOS ESTADOS E LOGS DOS ESTADOS
  useEffect(() => {
    if (dataProjeto) {
      setGeometria(dataProjeto.GetInteligenciaMercadoById.json.geometria)

      if (dataProjeto.GetInteligenciaMercadoById.json.endereco) {
        setEndereco(dataProjeto.GetInteligenciaMercadoById.json.endereco)
      }

      if (dataProjeto.GetInteligenciaMercadoById.json.filter) {
        setFilterData(dataProjeto.GetInteligenciaMercadoById.json.filter)
      }

      if (dataProjeto.GetInteligenciaMercadoById) {
        setDadosProjetoSalvo(dataProjeto.GetInteligenciaMercadoById)
      }

      if (dataProjeto.GetInteligenciaMercadoById.json.imoveisNaoComputados) {
        setNaoComputados([...dataProjeto.GetInteligenciaMercadoById.json.imoveisNaoComputados])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProjeto])
  useEffect(() => {
    window.addEventListener(
      'resize',
      function (event) {
        debounce(
          setWindowHeight({
            ...{
              h: window.innerHeight - 90,
              w: window.innerWidth - 345,
            },
          }),
          1000
        )
      },
      true
    )
  }, [])
  if (!dataProjeto) {
    return <SplashScreenComponent />
  }

  let nome = dataProjeto.GetInteligenciaMercadoById.nome || ''

  return (
    <>
      <AsideDefault />
      <MenuTopo nome={nome} projeto={dataProjeto} />
      <div className='wrapper d-flex flex-column flex-row-fluid'>
        <div
          className='content p-0 d-flex flex-column flex-column-fluid'
          // style={{paddingTop: '35px'}}
        >
          <div className='container-fluid p-0 border-1 border-radius'>
            <div className='' style={{height: windowHeight.h}}>
              <Tab.Container id='left-tabs-example' defaultActiveKey='item1'>
                <div style={{display: 'flex', height: windowHeight.h}}>
                  <Col
                    sm={3}
                    md={3}
                    xl={3}
                    xls={3}
                    className='d-flex flex-column justify-content-between p-0'
                    style={{
                      width: '243px',
                      backgroundColor: '#fff',
                      // zIndex: 99,
                      // bordersLeft: '1px solid #d0d0d0',
                      // borderTop: '1px solid #e7e7e7',
                    }}
                  >
                    <TabItens windowHeight={windowHeight.h} setActiveTab={setActiveTab}></TabItens>
                    <div className='dados-position'>
                      <ModalDados></ModalDados>
                    </div>
                  </Col>
                  <Col
                    // ref={tableRef}
                    sm={9}
                    className='p-0 '
                    style={{
                      width: windowHeight.w,
                      borderLeft: '1px solid #d0d0d0',
                      height: windowHeight.h,
                      overflowY: 'auto',
                      // overflow: 'auto'
                    }}
                  >
                    <Tab.Content>
                      <Tab.Pane eventKey='item1'>
                        <div className='all-start-between' style={{height: windowHeight.h}}>
                          {/* <Scrollbars style={{ height: windowHeight-100}}> */}
                          <Filtro height={windowHeight.h} />
                          {/* </Scrollbars> */}
                          <div
                            id='map'
                            className='flex-row position-relative'
                            style={{width: '100%', height: windowHeight.h}}
                          >
                            {showMap && (
                              <button
                                className='btn btn-primary position-absolute top-0 end-0 m-3 z-index-1'
                                onClick={handleShowPanel}
                              >
                                Mostrar Painel
                              </button>
                            )}
                            <MapViewGeojson height={windowHeight.h} />
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey='item2'>
                        <Segmentacao></Segmentacao>
                      </Tab.Pane>
                      <Tab.Pane eventKey='item3'>
                        {activeTab === 'resumoMercado' ? <ResumoMercado></ResumoMercado> : <></>}
                      </Tab.Pane>
                      <Tab.Pane eventKey='item4'>
                        {/* {activeTab === 'performance' ? <Performance></Performance> : <></>} */}
                        <Performance></Performance>
                      </Tab.Pane>
                      <Tab.Pane eventKey='item41'>
                        {/* {activeTab === 'sociodemografico' ? (
                          <PerformanceSocioDemografico></PerformanceSocioDemografico>
                        ) : (
                          <></>
                        )} */}
                        <PerformanceSocioDemografico></PerformanceSocioDemografico>
                      </Tab.Pane>
                      <Tab.Pane eventKey='item42'>
                        {/* {activeTab === 'performanceResumoMercado' ? (
                          <PerformanceResumoMercado></PerformanceResumoMercado>
                        ) : (
                          <></>
                        )} */}
                        <PerformanceResumoMercado></PerformanceResumoMercado>
                      </Tab.Pane>
                      <Tab.Pane eventKey='item43'>
                        {activeTab === 'performanceAnaliseConcorrentes' ? (
                          <PerformanceAnaliseConcorrentes></PerformanceAnaliseConcorrentes>
                        ) : (
                          <></>
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey='item49'>
                        {activeTab === 'desempenhovendas' ? (
                          <PerformanceDesempenhoVendas></PerformanceDesempenhoVendas>
                        ) : (
                          <></>
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey='item44'>
                        {activeTab === 'performanceVendas' ? (
                          <PerformanceVendas></PerformanceVendas>
                        ) : (
                          <></>
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey='item45'>
                        {/* <PerformanceVendas></PerformanceVendas> */}
                      </Tab.Pane>
                      <Tab.Pane eventKey='item46'>
                        {activeTab === 'performanceBrevesLancamentos' ? (
                          <PerformanceBrevesLancamentos></PerformanceBrevesLancamentos>
                        ) : (
                          <></>
                        )}
                      </Tab.Pane>
                      {/*     <Tab.Pane eventKey='item47'>
                  
                        <PerformanceMercadoRevenda></PerformanceMercadoRevenda>
                      </Tab.Pane>*/}
                      <Tab.Pane eventKey='item48'>
                        {activeTab === 'pontosInteresse' ? (
                          <PerformancePontosInteresse></PerformancePontosInteresse>
                        ) : (
                          <></>
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey='item5'>
                        {activeTab === 'avm' ? <Avm id={Number(idProjetoURL)}></Avm> : <></>}
                      </Tab.Pane>
                      <Tab.Pane eventKey='item6'>
                        {activeTab === 'ai' ? <Ai id={Number(idProjetoURL)}></Ai> : <></>}
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
