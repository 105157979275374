import {MapView} from '../MapView'
import {useContext, useEffect, useState} from 'react'
import {DataContext} from '../../context/DataContext'
import {HeaderPerformance} from '../Common/HeaderPerformance'
import {formatDistancia, formatNumber} from '../../../explorer/components/common'
import {FilterContext} from '../../context/FilterContext'
import {BiInfoCircle, BiPlus, BiTrash} from 'react-icons/bi'
import {Tipologia} from '../Types'
import {MaisInfoEmpreendimentos} from '../MaisInfoEmpreendimentos'
import {
  FilterRange,
  FilterSelect,
  RangeColumnFilter,
  SelectColumnFilter,
} from '../../../../components/UI/TableContainer/filters'
import TableContainer from '../../../../components/UI/TableContainer'

type Props = {
  label?: string
}
type PropMenu = {
  show: boolean
  id: number
}
// type PropFilter = {
//   empreendimento: string
//   incorporadora: string
// }
export function ResumoMercado({label}: Props) {
  const {data, removeItemTipologia, addItemTipologia} = useContext(DataContext)
  const {endereco, geometriaSelecionada} = useContext(FilterContext)
  // const [resetPaginationToggle, setResetPaginationToggle] = useState(false)
  const [dataComputados, setDataComputados] = useState([])
  const [dataNaoComputados, setDataNaoComputados] = useState([])
  // const [filterText, setFilterText] = useState<PropFilter>({
  //   empreendimento: null,
  //   incorporadora: null,
  // })
  const [show, setShow] = useState<PropMenu>({
    show: false,
    id: null,
  })
  const layersList = [] //getLayers(['view_ibge_explorer_renda_mensal_domiciliar'], json)
  const dataTable = []
  const dataTableNaoComputados = []

  // const TextField = styled.input`
  //   height: 32px;
  //   width: 200px;
  //   border-radius: 3px;
  //   border-top-left-radius: 5px;
  //   border-bottom-left-radius: 5px;
  //   border-top-right-radius: 0;
  //   border-bottom-right-radius: 0;
  //   border: 1px solid #e5e5e5;
  //   padding: 0 32px 0 16px;

  //   &:hover {
  //     cursor: pointer;
  //   }
  // `
  // const subHeaderComponentMemo = useMemo(() => {
  //   const handleClear = () => {
  //     if (filterText) {
  //       setResetPaginationToggle(!resetPaginationToggle)
  //       setFilterText([])
  //     }
  //   }

  //   return (
  //     <div style={{marginTop: 15}}>
  //       {/* <TextField
  //         id='search'
  //         type='text'
  //         placeholder='Filtrar pelo nome ou incorporadora'
  //         aria-label='Search Input'
  //         value={filterText}
  //         onChange={(e) => setFilterText(e.target.value)}
  //         autoFocus
  //       /> */}
  //       <Button
  //         type='button'
  //         onClick={handleClear}
  //         className=''
  //         style={{padding: '5px 12px 5px 12px'}}
  //       >
  //         X
  //       </Button>
  //     </div>
  //   )
  // }, [TextField, filterText, resetPaginationToggle])

  // function setFilter(field: string, text: string) {
  //   filterText[field] = text
  //   setFilterText({...filterText})
  // }

  // function getFilterValue(field) {
  //   // if (filterText.length > 0){
  //   return filterText[field]
  //   //  ((item)=>item.field === field)
  //   //  if (result.length > 0){
  //   //   return result[0].text || null
  //   //  }else{
  //   //   return null
  //   //  }
  //   // }else{return null}
  // }

  const columns = [
    {
      Header: () => 'Remover',
      id: 'remove', // 'id' is required
      Cell: ({row}) => {
        return (
          <button
            onClick={() => removeSamples(row.original.id)}
            className='btn btn-icon-danger btn-text-danger'
          >
            <BiTrash></BiTrash>
          </button>
        )
      },
    },
    {
      Header: () => 'Detalhes',
      id: 'expander', // 'id' is required
      Cell: ({row}) => {
        return (
          <button
            onClick={() =>
              setShow({
                show: true,
                id: row.original.id_empreendimento_orulo,
              })
            }
            className='btn btn-icon-info btn-text-info'
          >
            <BiInfoCircle className='fs-1'></BiInfoCircle>
          </button>
        )
      },
    },
    {
      Header: 'MCMV',
      accessor: 'affordable_program',
      Cell: ({row}) => <div style={{textAlign: 'center'}}> {row.original.affordable_program}</div>,
      Filter: SelectColumnFilter,
      filter: FilterSelect,
    },
    {
      Header: 'Empreendimento',
      accessor: 'nome',
      Filter: SelectColumnFilter,
      filter: FilterSelect,
    },

    {
      Header: 'Data Lanç.',
      accessor: 'data_lancamento',
      Cell: ({row}) => {
        if (row.original) {
          var dateParts = row.original.data_lancamento.split('/')

          if (dateParts.length === 1) {
            const date = new Date(row.original.data_lancamento)
            const day = String(date.getUTCDate()).padStart(2, '0')
            const month = String(date.getUTCMonth() + 1).padStart(2, '0') // +1 porque meses começam do 0
            const year = date.getUTCFullYear()

            const formattedDate = `${day}/${month}/${year}`

            return formattedDate
            // const dateA = new Date(row.original.data_lancamento);
            // const dateA = new Date('2024-11-12T00:00:00.000Z')
            // const yyyy = dateA.getFullYear();
            // let mm = dateA.getMonth() + 1; // Months start at 0!
            // let dd = dateA.getDate();

            // return  dd < 10 ? '0'+dd : dd + '/' + (mm < 10 ? '0'+mm :mm ) + '/' + yyyy;
          } else {
            return dateParts
          }
        }
      },
      sortType: (rowA, rowB, columnId, desc) => {
        var dateString = rowA.values[columnId] // Oct 23
        var dateParts = dateString.split('/')
        var dateObjectA = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])

        var dateStringB = rowB.values[columnId] // Oct 23
        var datePartsB = dateStringB.split('/')
        var dateObjectB = new Date(+datePartsB[2], datePartsB[1] - 1, +datePartsB[0])

        return dateObjectA > dateObjectB ? 1 : -1
      },
    },
    {
      Header: 'Incorporadora',
      accessor: 'incorporadora',
      Filter: SelectColumnFilter,
      filter: FilterSelect,
    },
    {
      Header: () => <div style={{textAlign: 'center'}}>Estoque</div>,
      accessor: 'estoque',
      Cell: ({row}) => <div style={{textAlign: 'center'}}> {row.original.estoque}</div>,
      Filter: RangeColumnFilter,
      filter: FilterRange,
    },
    {
      Header: () => <div style={{textAlign: 'center'}}>Área Privativa</div>,
      accessor: 'area_privativa',
      Cell: ({row}) => (
        <div style={{textAlign: 'center'}}>
          {' '}
          {formatNumber(row.original.area_privativa, 'inteiro')}
        </div>
      ),
      Filter: RangeColumnFilter,
      filter: FilterRange,
    },
    {
      Header: () => <div style={{textAlign: 'center'}}>Quartos</div>,
      accessor: 'quartos',
      Cell: ({row}) => (
        <div style={{textAlign: 'center'}}> {formatNumber(row.original.quartos, 'inteiro')}</div>
      ),
      Filter: RangeColumnFilter,
      filter: FilterRange,
    },
    {
      Header: () => <div style={{textAlign: 'center'}}>Vagas</div>,
      accessor: 'vagas',
      Cell: ({row}) => <div style={{textAlign: 'center'}}> {row.original.vagas}</div>,
      Filter: RangeColumnFilter,
      filter: FilterRange,
    },
    {
      Header: () => <div style={{textAlign: 'center'}}>Preço m²</div>,
      accessor: 'preco_m2',
      Cell: ({row}) => (
        <div style={{textAlign: 'right'}}> {formatNumber(row.original.preco_m2, 'currency')}</div>
      ),
      Filter: RangeColumnFilter,
      filter: FilterRange,
    },
    {
      Header: () => <div style={{textAlign: 'center'}}>Preço Total</div>,
      accessor: 'preco_total',
      Cell: ({row}) => {
        return (
          <div style={{textAlign: 'right'}}>
            {' '}
            {formatNumber(row.original.preco_total, 'currency')}
          </div>
        )
      },
      Filter: RangeColumnFilter,
      filter: FilterRange,
      className: 'text-center',
    },

    {
      Header: 'Tpo',
      accessor: 'tipo',
      Filter: SelectColumnFilter,
      filter: FilterSelect,
    },
    {
      Header: 'Bairro',
      accessor: 'bairro',
    },
    {
      Header: 'Endereço',
      accessor: 'endereco',
    },

    {
      Header: () => <div style={{textAlign: 'center'}}>Total unidades</div>,
      accessor: 'total_unidades',
      Cell: ({row}) => (
        <div style={{textAlign: 'center'}}>
          {' '}
          {formatNumber(row.original.total_unidades, 'inteiro')}
        </div>
      ),
      Filter: RangeColumnFilter,
      filter: FilterRange,
    },
    {
      Header: () => <div style={{textAlign: 'center'}}>VSO</div>,
      accessor: 'vso',
      Cell: ({row}) => (
        <div style={{textAlign: 'center'}}> {formatNumber(row.original.vso, 'inteiro')}</div>
      ),
      Filter: RangeColumnFilter,
      filter: FilterRange,
    },

    {
      Header: () => <div style={{textAlign: 'center'}}>Distância</div>,
      accessor: 'distancia',
      Cell: ({row}) => {
        return <div style={{textAlign: 'center'}}>{formatDistancia(row.original.distancia)}</div>
      },
      Filter: RangeColumnFilter,
      filter: FilterRange,
    },
  ]

  const columnsNC = [
    {
      Header: () => 'Incluir',
      id: 'incluir', // 'id' is required
      Cell: ({row}) => {
        return (
          <button
            onClick={() => addSamples(row.original.id)}
            className='btn btn-icon-success btn-text-danger'
          >
            <BiPlus className='fs-1'></BiPlus>
          </button>
        )
      },
    },
    {
      Header: () => 'Detalhes',
      id: 'expander', // 'id' is required
      Cell: ({row}) => {
        return (
          <button
            onClick={() =>
              setShow({
                show: true,
                id: row.original.id,
              })
            }
            className='btn btn-icon-info btn-text-info'
          >
            <BiInfoCircle className='fs-1'></BiInfoCircle>
          </button>
        )
      },
    },
    {
      Header: 'MCMV',
      accessor: 'affordable_program',
      Cell: ({row}) => <div style={{textAlign: 'center'}}> {row.original.affordable_program}</div>,
      Filter: SelectColumnFilter,
      filter: FilterSelect,
    },
    {
      Header: 'Empreendimento',
      accessor: 'nome',
      Filter: SelectColumnFilter,
      filter: FilterSelect,
    },
    {
      Header: 'Data Lanç.',
      accessor: 'data_lancamento',
      Cell: ({row}) => {
        if (row.original) {
          var dateParts = row.original.data_lancamento.split('/')

          if (dateParts.length === 1) {
            const date = new Date(row.original.data_lancamento)

            const day = String(date.getUTCDate()).padStart(2, '0')
            const month = String(date.getUTCMonth() + 1).padStart(2, '0') // +1 porque meses começam do 0
            const year = date.getUTCFullYear()

            const formattedDate = `${day}/${month}/${year}`

            return formattedDate
            // const dateA = new Date(row.original.data_lancamento);
            // const dateA = new Date('2024-11-12T00:00:00.000Z')
            // const yyyy = dateA.getFullYear();
            // let mm = dateA.getMonth() + 1; // Months start at 0!
            // let dd = dateA.getDate();

            // return  dd < 10 ? '0'+dd : dd + '/' + (mm < 10 ? '0'+mm :mm ) + '/' + yyyy;
          } else {
            return dateParts
          }
        }
      },
      sortType: (rowA, rowB, columnId, desc) => {
        var dateString = rowA.values[columnId] // Oct 23
        var dateParts = dateString.split('/')
        var dateObjectA = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])

        var dateStringB = rowB.values[columnId] // Oct 23
        var datePartsB = dateStringB.split('/')
        var dateObjectB = new Date(+datePartsB[2], datePartsB[1] - 1, +datePartsB[0])

        return dateObjectA > dateObjectB ? 1 : -1
      },
    },
    {
      Header: 'Incorporadora',
      accessor: 'incorporadora',
      Filter: SelectColumnFilter,
      filter: FilterSelect,
    },
    {
      Header: () => <div style={{textAlign: 'center'}}>Estoque</div>,
      accessor: 'estoque',
      Cell: ({row}) => <div style={{textAlign: 'center'}}> {row.original.estoque}</div>,
      Filter: RangeColumnFilter,
      filter: FilterRange,
    },
    {
      Header: () => <div style={{textAlign: 'center'}}>Área Privativa</div>,
      accessor: 'area_privativa',
      Cell: ({row}) => (
        <div style={{textAlign: 'center'}}>
          {' '}
          {formatNumber(row.original.area_privativa, 'inteiro')}
        </div>
      ),
      Filter: RangeColumnFilter,
      filter: FilterRange,
    },
    {
      Header: () => <div style={{textAlign: 'center'}}>Quartos</div>,
      accessor: 'quartos',
      Cell: ({row}) => (
        <div style={{textAlign: 'center'}}> {formatNumber(row.original.quartos, 'inteiro')}</div>
      ),
      Filter: RangeColumnFilter,
      filter: FilterRange,
    },
    {
      Header: () => <div style={{textAlign: 'center'}}>Vagas</div>,
      accessor: 'vagas',
      Cell: ({row}) => <div style={{textAlign: 'center'}}> {row.original.vagas}</div>,
      Filter: RangeColumnFilter,
      filter: FilterRange,
    },
    {
      Header: () => <div style={{textAlign: 'center'}}>Preço m²</div>,
      accessor: 'preco_m2',
      Cell: ({row}) => (
        <div style={{textAlign: 'right'}}> {formatNumber(row.original.preco_m2, 'currency')}</div>
      ),
      Filter: RangeColumnFilter,
      filter: FilterRange,
    },
    {
      Header: () => <div style={{textAlign: 'center'}}>Preço Total</div>,
      accessor: 'preco_total',
      Cell: ({row}) => {
        return (
          <div style={{textAlign: 'right'}}>
            {' '}
            {formatNumber(row.original.preco_total, 'currency')}
          </div>
        )
      },
      Filter: RangeColumnFilter,
      filter: FilterRange,
      className: 'text-center',
    },

    {
      Header: 'Tpo',
      accessor: 'tipo',
      Filter: SelectColumnFilter,
      filter: FilterSelect,
    },
    {
      Header: 'Bairro',
      accessor: 'bairro',
    },
    {
      Header: 'Endereço',
      accessor: 'endereco',
    },

    {
      Header: () => <div style={{textAlign: 'center'}}>Total unidades</div>,
      accessor: 'total_unidades',
      Cell: ({row}) => (
        <div style={{textAlign: 'center'}}>
          {' '}
          {formatNumber(row.original.total_unidades, 'inteiro')}
        </div>
      ),
      Filter: RangeColumnFilter,
      filter: FilterRange,
    },
    {
      Header: () => <div style={{textAlign: 'center'}}>VSO</div>,
      accessor: 'vso',
      Cell: ({row}) => (
        <div style={{textAlign: 'center'}}> {formatNumber(row.original.vso, 'inteiro')}</div>
      ),
      Filter: RangeColumnFilter,
      filter: FilterRange,
    },

    {
      Header: () => <div style={{textAlign: 'center'}}>Distância</div>,
      accessor: 'distancia',
      Cell: ({row}) => {
        return <div style={{textAlign: 'center'}}>{formatDistancia(row.original.distancia)}</div>
      },
      Filter: RangeColumnFilter,
      filter: FilterRange,
    },
  ]
  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    data.map((item) => {
      // eslint-disable-next-line array-callback-return
      item.tipologias.map((itemTipologia: Tipologia) => {
        if (itemTipologia.computed) {
          dataTable.push({
            id: itemTipologia.id_tipologia,
            id_empreendimento: item.id_empreendimento,
            id_empreendimento_orulo: item.id_empreendimento_orulo,
            nome: item.nome,
            incorporadora: item.nome_incorporador,
            endereco: item.endereco,
            quartos: itemTipologia.bedrooms,
            data_lancamento: item.data_lancamento,
            area_privativa: itemTipologia.private_area,
            total_unidades: itemTipologia.total_units,
            vso: itemTipologia.vendas_perc_tip,
            estoque: itemTipologia.stock,
            vagas: itemTipologia.parking,
            preco_m2: itemTipologia.original_price / itemTipologia.private_area,
            preco_total: itemTipologia.original_price,
            bairro: item.bairro,
            distancia: item.distancia,
            tipo: itemTipologia.type,
            affordable_program: item.affordable_program === 1 ? 'Sim' : 'Não',
          })
        } else {
          dataTableNaoComputados.push({
            id: itemTipologia.id_tipologia,
            id_empreendimento: item.id_empreendimento,
            id_empreendimento_orulo: item.id_empreendimento_orulo,
            nome: item.nome,
            incorporadora: item.nome_incorporador,
            endereco: item.endereco,
            quartos: itemTipologia.bedrooms,
            data_lancamento: item.data_lancamento,
            area_privativa: itemTipologia.private_area,
            total_unidades: itemTipologia.total_units,
            vso: itemTipologia.vendas_perc_tip,
            estoque: itemTipologia.stock,
            vagas: itemTipologia.parking,
            preco_m2: itemTipologia.original_price / itemTipologia.private_area,
            preco_total: itemTipologia.original_price,
            bairro: item.bairro,
            distancia: item.distancia,
            tipo: itemTipologia.type,
            affordable_program: item.affordable_program === 1 ? 'Sim' : 'Não',
          })
        }
      })

      setDataComputados(dataTable)
      setDataNaoComputados(dataTableNaoComputados)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  if (!data) {
    return <>Carregando...</>
  }

  function removeSamples(id: number) {
    removeItemTipologia(id)
  }

  function addSamples(id: number) {
    addItemTipologia(id)
  }

  return (
    <>
      <div className=' d-flex flex-column justify-content-start p-10'>
        <HeaderPerformance titulo={'Resumo de Mercado'}></HeaderPerformance>
        <div className='content pt-5'>
          <div className='row'>
            <div className='col-12'>
              <div className='card bgi-no-repeat card-xl-stretch mb-xl-8 border-2 box-performance'>
                <div className='map-painel d-flex w-100'>
                  <div className='mapa col-12'>
                    <MapView
                      data={data}
                      layer={[layersList]}
                      zoom={14}
                      height='500px'
                      center={{lat: endereco.latitude, lng: endereco.longitude}}
                      geometria={geometriaSelecionada}
                    ></MapView>
                    <div className=' col-12 p-5 '>
                      <TableContainer columns={columns} data={dataComputados}></TableContainer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row px-5'>
            <h3 className='pb-10 pt-5'>Não computados</h3>
            <div className='card bgi-no-repeat card-xl-stretch mb-xl-8 border-2 box-performance '>
              <div className='map-painel d-flex w-100 '>
                <div className=' col-12 p-5 '>
                  <TableContainer columns={columnsNC} data={dataNaoComputados}></TableContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MaisInfoEmpreendimentos
        setShow={setShow}
        show={show}
        height={'calc(100vh)'}
      ></MaisInfoEmpreendimentos>
    </>
  )
}
