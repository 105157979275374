import {create} from 'zustand'
import {Endereco, Feature, Filter, LotesProps, LotesRefProps} from './types'
import {ApiUrbit} from '../../../../infra/http/urbit'
import {
  CloseLoaderInfoOverlay,
  LoaderInfoOverlay,
} from '../../../components/UI/Loader/LoaderOverlay'
import Swal from 'sweetalert2'

type DataContextProps = {
  lotes: LotesRefProps
  lotesSelecionados: LotesProps[]
  endereco: Endereco
  filter: {
    tese: string
    idEstado: number
    nomeEstado: string
    idCidade: number
    nomeCidade: string
    idBairro: number
    bairro: string
    zonaUso?: string[]
    areaMinimaTerreno?: number
    areaMaximaTerreno?: number
    areaMinimaLote?: number
    pavimentos?: number
    tipoUso?: string
    proprietarioUnico?: boolean | null
  }
  setLotes: (lotes: LotesProps[]) => void
  setLotesSelecionados: (lotes: LotesProps[]) => void
  setEndereco: (endereco: Endereco) => void
  fetchData: (feature: Feature) => void
  setFilter: (filter: Filter) => void
  findExistLotesSelecionados: (item: string) => LotesProps[]
  removeItemLotesSelecionados: (item: string) => void
}

export const useDataTese = create<DataContextProps>((set, get) => ({
  lotes: null,
  lotesSelecionados: [],
  endereco: null,
  filter: {
    tese: null,
    idEstado: null,
    nomeEstado: null,
    idCidade: null,
    nomeCidade: null,
    idBairro: null,
    bairro: null,
    zonaUso: null,
    areaMaximaTerreno: null,
    areaMinimaTerreno: null,
    areaMinimaLote: null,
    pavimentos: null,
    tipoUso: null,
    proprietarioUnico: null,
  },
  setLotes: (lotes: LotesProps[]) => {
    console.log(lotes)
  },
  setEndereco: (endereco: Endereco) => {
    console.log(endereco)
  },
  fetchData: async (geom: Feature) => {
    LoaderInfoOverlay('Carregando imóveis...')

    let url = `lotes-prospeccao`
    await ApiUrbit.post(url, get().filter, (lotesProspecaco) => {
      if (lotesProspecaco.error === false) {
        set({lotes: lotesProspecaco.data})
      } else {
        Swal.fire(
          '',
          'Erro ao tentar carregar os lotes, tente aplicar o filtro novamente.',
          'warning'
        )
      }
    })

    CloseLoaderInfoOverlay()
  },
  setFilter: (filter: Filter) => {
    set((state) => ({filter: filter}))
  },
  setLotesSelecionados: (lotes: LotesProps[]) => {
    set((state) => ({lotesSelecionados: [...state.lotesSelecionados, ...lotes]}))
  },
  removeItemLotesSelecionados: (item) => {
    console.log('removeItemLotesSelecionados antes')
    console.log(get().lotesSelecionados)

    set((state) => ({
      lotesSelecionados: state.lotesSelecionados.filter((lote, index) => {
        if (lote.numero_contribuinte.substring(0, 10) === item) {
          return false
        } else {
          return lote
        }
      }),
    }))
  },

  findExistLotesSelecionados(item) {
    // eslint-disable-next-line array-callback-return
    let selecionados = get().lotesSelecionados.filter((lote, index) => {
      if (lote.numero_contribuinte.substring(0, 10) === item) {
        return lote
      }
    })

    return selecionados
  },
}))
