import {useState} from 'react'
import {Modal, Spinner} from 'react-bootstrap'
import * as XLSX from 'xlsx'
import {BiExport} from 'react-icons/bi'
import DateRangePickerComponent from './DataPicker'
import {QueryGetLogAcessoRecursosExport} from '../../../../../backoffice/graphql/services/LogAcesso'
import {ChosenEmpresaUsuario} from './ChosenUsuariosEmpresa'
import {DateTimToView} from '../../../../../components/util'

interface Props {
  idEmpresa: any
}
export function ModalExportLogs({idEmpresa}: Props) {
  const [show, setShow] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [focusedInput, setFocusedInput] = useState(null)

  function closeModal() {
    setShow(false)
  }
  function openModal() {
    setShow(true)
  }

  const [valueUsuario, setValueUsuario] = useState('')

  const {getLogs, loading} = QueryGetLogAcessoRecursosExport()

  function exportXls(dataInicio: any, dataFim: any, data: any) {
    const dataToExport = []
    let total = 0
    if (data) {
      // eslint-disable-next-line array-callback-return
      data.GetLogAcessoRecursosByEmpresa.result.map((response) => {
        total++

        dataToExport.push({
          ID: response.id === null ? 'Não informado' : response.id,
          'Nome Usuário': response.user?.name === null ? 'Não informado' : response.user.name,
          Ferramenta: response.ferramenta === null ? 'Não informado' : response.ferramenta,
          Funcionalidade:
            response.funcionalidade === null ? 'Não informado' : response.funcionalidade,
          'Data Acesso':
            response.data_acesso === null ? 'Não informado' : DateTimToView(response.data_acesso),
        })
      })

      dataToExport.push({
        ID: 'Total de Acessos',
        'Nome Usuário': null,
        Ferramenta: null,
        Funcionalidade: null,
        'Data Acesso': total,
      })

      const worksheet = XLSX.utils.json_to_sheet(dataToExport)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, worksheet, 'Planilha de Dados')
      XLSX.writeFile(wb, `RelatórioLogsFuncionarios_${dataInicio}_${dataFim}.xlsx`)
    }
  }

  function converterData(dataString) {
    const data = new Date(dataString)
    const dia = ('0' + data.getDate()).slice(-2)
    const mes = ('0' + (data.getMonth() + 1)).slice(-2)
    const ano = data.getFullYear()
    return `${ano}-${mes}-${dia}`
  }

  function handleGetLogs(e) {
    e.preventDefault()
    let filtro = null
    if (valueUsuario) {
      filtro = {
        filtro: {
          fields: [
            {
              field: ['users.email'],
              operator: '=',
              value: valueUsuario,
            },
          ],
        },
      }
    }
    const startDateConverted = converterData(startDate)
    const endDateConverted = converterData(endDate)
    getLogs({
      variables: {
        idEmpresa: idEmpresa,
        pagination: {
          pagina: 0,
          quantidade: 100000,
        },
        dataFim: endDateConverted,
        dataInicio: startDateConverted,
        ...filtro,
      },
    })
      .then((result) => {
        if (result.data) {
          exportXls(startDate, endDate, result.data)
        }
      })
      .catch((error) => {
        console.error(error) // Trata possíveis erros na requisição
      })
  }

  return (
    <>
      <button
        onClick={openModal}
        className='btn btn-light-primary btn-sm d-block me-2'
        style={{width: '100px !important'}}
      >
        Exportar Relatório <BiExport className='fs-2'></BiExport>
      </button>
      <Modal centered animation show={show} onHide={() => closeModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Exportar Relatório</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-lg'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='form-group'>
                <form onSubmit={handleGetLogs}>
                  <div className='row'>
                    <div className='col-lg-12 col-md-6 col-sm-6'>
                      <div className='form-group mb-4'>
                        <ChosenEmpresaUsuario
                          valueUsuario={valueUsuario}
                          setValueUsuario={(value: string) => {
                            setValueUsuario(value)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row mb-8'>
                    <div className='col-lg-12 col-md-6 col-sm-6'>
                      <DateRangePickerComponent
                        startDate={startDate}
                        endDate={endDate}
                        onStartDateChange={setStartDate}
                        onEndDateChange={setEndDate}
                        focusedInput={focusedInput}
                        setFocusedInput={setFocusedInput}
                      />
                    </div>
                  </div>

                  <div className='col-md-12 d-flex mrt-1' style={{justifyContent: 'flex-end'}}>
                    <button
                      onClick={() => closeModal()}
                      className='btn btn-danger pull-right'
                      type='button'
                      data-bs-dismiss='modal'
                    >
                      Fechar
                    </button>
                    <button disabled={loading} type='submit' className='btn btn-success mx-2'>
                      {loading ? (
                        <>
                          <Spinner size='sm' /> {' Carrengado'}
                        </>
                      ) : (
                        'Exportar'
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
