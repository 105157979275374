import {BiLink} from 'react-icons/bi'
import {CardTables} from '../../../../components/UI/CardTables'
import PaginationComponent from '../../../../components/UI/PaginationComponents'
import {formatNumber} from '../../../../components/util'
import {QueryGetEmpreendimentos, QueryGetEmpreendimentosMapa} from '../../../../graphql'
import {CoordinatesToNumber, CoordinatesToPath, MapView} from './MapView'
import * as turf from '@turf/turf'
import {LoaderInfo} from '../../../../components/UI/Loader/LoaderInfo'
import {Loader} from '../../../../components/UI/Loader/Loader'
type Props = {
  active: boolean
  filtro: any
  objectFilter: {}
}

const TableMap: React.FC<Props> = ({active, filtro, objectFilter}) => {
  let latlng = []
  const {data, loading, error} = QueryGetEmpreendimentosMapa({
    variables: {
      pagination: {
        pagina: 0,
        quantidade: 5000,
      },
      filtro: {
        fields: filtro,
        ...objectFilter,
      },
      // situation: 1,
    },
  })

  if (loading) {
    return <Loader text={'Carregando'}></Loader>
  }

  return (
    <div className='content  d-flex flex-column flex-column-fluid'>
      <div className='col-xl-12'>
        <div className='card-xxl-stretch '>
          <div className='card-form px-12 py-12 mb-6'>
            {data ? (
              data.GetEmpreendimentosMapa.result.length > 0 ? (
                // eslint-disable-next-line array-callback-return
                data.GetEmpreendimentosMapa.result.map((empreendimento, item) => {
                  empreendimento.empreendimento_terreno.map((response, item) => {
                    // totalArea += response.terreno ? response.terreno.area_iptu : 0
                    // totalTestada += response.terreno ? response.terreno.testada : 0
                    if (response.terreno) {
                      if (response.terreno.marcacao) {
                        let path = null
                        if (response.terreno.marcacao.type === 'MultiPolygon') {
                          path = CoordinatesToPath(response.terreno.marcacao['coordinates'][0][0])
                        } else if (response.terreno.marcacao.type === 'Polygon') {
                          path = CoordinatesToPath(response.terreno.marcacao['coordinates'][0])
                        }
                        if (response.terreno.marcacao) {
                          latlng.push({
                            path: path,
                            center: turf.centroid(CoordinatesToNumber(response.terreno.marcacao)),
                            id: response.terreno.id,
                            idEmpreendimento: empreendimento.id,
                            endereco: response.terreno.endereco,
                            area: response.terreno.area_iptu,
                            id_cidade: response.terreno.id_cidade,
                            editable: false,
                            color: empreendimento.empreendimento_situacao.cor ?? '#00000044',
                            //  response.terreno.terreno_situacao_cliente?.cor ?? '#00000044',
                            colorTemperatura:
                              empreendimento.empreendimento_temperatura?.cor ?? '#00000044',
                            terrenoSituacao: response.terreno.terreno_situacao_cliente?.nome,
                            terrenoUsuario: response.terreno.users?.name,

                            empreendimentoAreaPrivativa: empreendimento.area_total_privativa,
                            empreendimentoAreaTerreno: empreendimento.area_total_terreno,
                            empreendimentoVGV: empreendimento.vgv
                              ? formatNumber(empreendimento.vgv, 'currency_inteiro')
                              : '-',
                            empreendimentoUsuario: empreendimento.users.name,
                            empreendimentoSituacao: empreendimento.empreendimento_situacao?.nome,
                            empreendimentoTemperatura:
                              empreendimento.empreendimento_temperatura?.nome,
                          })
                        }
                      }
                    }
                  })
                })
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
            <MapView latLng={latlng} idEmpreendimento={0}></MapView>
            {/* <CardTables
              headerTitle={'Empreendimentos'}
              headerSubtTitle={`Até o momento ${
                data ? data.GetEmpreendimentosMapa.pageInfo.totalItems : 0
              } empreendimento(s) registrado(s).`}
              // headerToolbar={
              //   // <button
              //   //   className='btn btn-info me-5'
              //   //   onClick={() => {
              //   //     explorerOpen()
              //   //   }}
              //   // >
              //   //   <BiCompass className='fs-2'></BiCompass> Explorer
              //   // </button>
              // }
              footer={
                data ? (
                  <PaginationComponent
                    pagesInfo={data.GetEmpreendimentosMapa.pageInfo}
                    setPagesInfo={(pagina: number, quantidade: number) => {
                      // console.log({pagina: pagina, quantidade: quantidade})
                      // setPaginacao({pagina: pagina, quantidade: quantidade})
                    }}
                  ></PaginationComponent>
                ) : (
                  <></>
                )
              }
            >
              <div className='table-responsive  '>
                <table className='table table-striped table-row-dashed  gy-2 gs-6  table-row-bordered   table-hover  table-row-gray-300 align-middle gs-0 gy-4'>
                  <thead>
                    <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                      <th className=''>
                        <td></td>
                      </th>
                      <th className=''>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                          id
                        </div>
                      </th>
                      <th className='text-center'>Temperatura</th>
                      <th className='text-center'>Situação</th>
                      <th className=''>Endereço</th>
                      <th className=''>Área</th>
                      <th className=''>Zoneamento</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data ? (
                      data.GetEmpreendimentosMapa.result.length > 0 ? (
                        data.GetEmpreendimentosMapa.result.map((response, index) => {
                          return (
                            <tr key={response.id} id={`line${index}`}>
                              <td className=''>
                                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                  <a
                                    href={`/empreendimentos/details/${response.id}#composicao-terrenos`}
                                    target='_blank'
                                    rel='noreferrer'
                                  >
                                    <BiLink></BiLink>{' '}
                                  </a>
                                </div>
                              </td>
                              <td className=''>
                                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                  {response.id}
                                </div>
                              </td>
                              <td className=' text-center '>
                                <div className='d-flex justify-content-center align-items-center gap-1 '>
                                  {response.empreendimento_temperatura ? (
                                    <>
                                      <span
                                        className='badge badge-temperatura fw-bold me-auto px-4 py-2 p-5 '
                                        style={{
                                          backgroundColor: response.empreendimento_temperatura?.cor,
                                        }}
                                      >
                                        {response.empreendimento_temperatura?.nome}
                                      </span>
                                    </>
                                  ) : (
                                    <span className='flex-fill'> </span>
                                  )}
                                </div>
                              </td>
                              <td className='text-center'>
                                <div className='d-flex justify-content-center align-items-center gap-1 '>
                                  {response.empreendimento_situacao ? (
                                    <>
                                      <span
                                        className='badge  fw-bold me-auto px-1 py-2'
                                        style={{
                                          backgroundColor: response.empreendimento_situacao?.cor,
                                          color: response.empreendimento_situacao?.cor,
                                        }}
                                      >
                                        .
                                      </span>
                                      <span className='flex-fill'>
                                        {' '}
                                        {response.empreendimento_situacao?.nome}
                                      </span>
                                    </>
                                  ) : (
                                    <span className='flex-fill'>
                                      {' '}
                                      {response.empreendimento_situacao?.nome}
                                    </span>
                                  )}
                                </div>
                              </td>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                      {response.nome}
                                    </span>

                                    {response.users.name}
                                  </div>
                                </div>
                              </td>
                              <td>{response.area_total_terreno}</td>
                              <td>
                                {response.empreendimento_terreno.length > 0
                                  ? response.empreendimento_terreno[0].terreno.zoneamento
                                  : '-'}{' '}
                              </td>
                            </tr>
                          )
                        })
                      ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    )}
                    <tr>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CardTables> */}

            {/* <Table active={descart} filtro={filter} objectFilter={objectFilter} /> */}
          </div>
        </div>
      </div>
    </div>
  )
}
export {TableMap}
